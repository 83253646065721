import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ServiceHeaderService } from "../header/_services/service-header.service";
import { LoaderService } from "../../shared/services/loader.service";
import { AuthJwtService } from "../../shared/services/auth-jwt.service";
import { ConfirmAlertDeleteService } from '../../shared/services/confirm-alert-delete.service';
import { LogoutService } from '../../shared/services/logout.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from '../../shared/services/alert.service';
@Component({
  selector: 'app-upgrade-now',
  templateUrl: './upgrade-now.component.html',
  styleUrls: ['./upgrade-now.component.css']
})
export class UpgradeNowComponent implements OnInit {
    location_id:any='';
    Subscription_list:any;
    selected_type:string="annually";
    selected_plan:string="";
    selected_billing_id:string='';
    selected_subscription_id:string='';
    save_billing_id:string='';
    save_subscription_id:string='';
    save_type:string="annually";
    prev_billing_type='';
    save_plan:string="";
    numberOfUser:any="";
    previousUser:any=null;
    @Input() modalRef: any;
    firstTime:boolean=true;
    @Output() updateDetails = new EventEmitter();
    currentOffice:any=0;
    currentEmployee:any=0;
    is_upgrade:any=+sessionStorage.getItem('upgrade')==0 || sessionStorage.getItem('upgrade')==undefined || sessionStorage.getItem('upgrade')==null?false:true;
    constructor(private _serviceHeaderService:ServiceHeaderService,
        private _loaderService:LoaderService,
        private _authJwtService:AuthJwtService,
        public _ConfirmAlertDeleteService: ConfirmAlertDeleteService,
        private _logoutService: LogoutService,
        private modalService: BsModalService,
        private _loaderservice: NgxUiLoaderService,
        private _alertService:AlertService){
    }
    ngOnInit(): void {
      this.GetBillingSubscriptionList(this._authJwtService.shared.data?.billing_type);
      this.setData();
      if(this.SubsData!=null)
      this.OnSubscriptionChange(this.SubsData.subscription_id);
      this.getLocationId();
    }
    setData(){
      this.numberOfUser=this._authJwtService.shared.data!=null?+this._authJwtService.shared.data.company_subscription_upgrated==1?this._authJwtService.shared.company_permission_list!=null?this._authJwtService.shared.company_permission_list.employee_addition_limit.limitation:'':'':'';
      this.previousUser=this.numberOfUser;
      this.selected_type=this._authJwtService.shared.data==null?'monthly':this._authJwtService.shared.data.billing_type.toLowerCase();
      this.save_type=this.selected_type;
      this.selected_plan=this._authJwtService.shared.data==null?'':this._authJwtService.shared.data.name.toLowerCase();
      this.prev_billing_type=this._authJwtService.shared.data==null?'':this._authJwtService.shared.data.billing_type.toLowerCase();
      this.save_plan=this.selected_plan;
      this.SubsData=this._authJwtService.shared.data==null?'':this._authJwtService.shared.data;
      this.currentOffice=this._authJwtService.shared.office_count;
      this.currentEmployee=this._authJwtService.shared.user_count;
    }
    selected_subscription:any;
    BillingCycle_list:any;
    OnSubscriptionChange(event:any){

    this.selected_subscription=event;
    const params={
      subscription_id:event
    }
    this._serviceHeaderService.GetBillingCycleList(params).subscribe((res: any) => {
      if(res.status==1){
        this.BillingCycle_list=res.data;
        this._loaderService.hideLoader();
      }
      else{
        this._loaderService.hideLoader();
      }
    },err=>{
      this._loaderService.hideLoader();
    });
    }

    SubsData:any;
    BillingSubscription:any=null;
    GetBillingSubscriptionList(value:any){
      const params={
        billing_type: value!=undefined?value.charAt(0).toUpperCase() + value.slice(1):'Monthly'
      }
      this._serviceHeaderService.getBillingSubscriptionList(params).subscribe(res=>{
        
          if(res.status==1){
            
            this.Subscription_list=res.data;
            if(this.firstTime){
              this.firstTime=false;
              this.selected_billing_id=this._authJwtService.shared.data==null?'':this._authJwtService.shared.data.billing_id;
              this.selected_subscription_id=this._authJwtService.shared.data==null?'':this._authJwtService.shared.data.subscription_id;
              this.save_billing_id=this.selected_billing_id;
              this.save_subscription_id=this.selected_subscription_id;
              for(let i in this.Subscription_list){
                if(this.Subscription_list[i].name.toLowerCase()==this.selected_plan){
                  this.totalUserOnPlan=this.Subscription_list[i].user_permission_count;
                }
              }
              
            }
            else{
              if(this.selected_type==this.save_type){
                this.selected_plan=this.save_plan;
                this.selected_billing_id=this.save_billing_id;
                this.selected_subscription_id=this.save_subscription_id;
                for(let i in this.Subscription_list){
                  if(this.Subscription_list[i].name.toLowerCase()==this.selected_plan){
                    this.totalUserOnPlan=this.Subscription_list[i].user_permission_count;
                  }
                }
              }
              
            }
            this._loaderService.hideLoader();
          }
          else{
            this._loaderService.hideLoader();
          }
      },err=>{
        this._loaderService.hideLoader();
      });
  }

  changeType(value){
    if(this.previousUser!='' && value!=this.prev_billing_type){
      this._alertService.showAlert("You can't change the billing cycle",'error');
    }
    else{
      this.selected_type=value;
      this.selected_billing_id='';
      this.selected_subscription_id='';
      this.totalUserOnPlan=0;
      this.selected_plan='';
      this.GetBillingSubscriptionList(value);
    }
    
  }

  totalUserOnPlan:any=0;
  onCardClick(value,item){
   if(item.office_permission_count != null && item.office_permission_count<this.currentOffice){
      this._alertService.showAlert('Total office must be less than '+item.office_permission_count +'.Delete offices to get this plan.','error');
    }
    else if(item.user_permission_count != null && item.user_permission_count<this.currentEmployee){
      this._alertService.showAlert('Total office must be less than '+item.user_permission_count +'.Delete offices to get this plan.','error');
    }
    else{
      this.selected_plan=value;
      this.selected_billing_id=item.id;
      this.selected_subscription_id=item.subscription_id;
      this.totalUserOnPlan=item.user_permission_count;
    }
  }


  public signOut() {
    // let conformation = confirm("do you want to sign out?");
    // if (conformation == true) {
    // this._logoutService.LogOut();
    // }
    this._ConfirmAlertDeleteService.confirmButton("Do you want to sign out?").subscribe(
      (resp) => {
        if (resp) {
          this._logoutService.LogOut();
        }
      }
    );
  }


  modalRef_square: BsModalRef;
  plan:string='';
  onUpgrade(template:any){
    if(this.selected_subscription_id==''){
      this._alertService.showAlert('Please select a plan','error');
    }
    else if(this.numberOfUser==''){
      this._alertService.showAlert('Number of user is required','error');
    }
    else if(this.selected_plan.toLowerCase()=='standard' && +this.numberOfUser>100){
      this._alertService.showAlert('If standard plan is selected then total number of user must not exceed 100','error');
    }
    else if(this.previousUser!='' && +this.numberOfUser<+this.previousUser && this.selected_type=='annually'){
      this._alertService.showAlert("When billing type is annual,you can't decrease no. of user",'error');
    }
    else{
      this._loaderservice.start();
      if(this._authJwtService.shared.data==null || (this._authJwtService.shared.data.square_customer_id == null && this._authJwtService.shared.data.square_card_id==null && this._authJwtService.shared.data.square_subscription_id==null)){
        this.plan=(this.selected_plan.toUpperCase()+'_'+this.selected_type.toUpperCase());
        const config = {
          backdrop: true,
          ignoreBackdropClick: true
        };
        this.modalRef_square = this.modalService.show(template, Object.assign(config,{ class: 'gray modal-sm' }));
        
      }
      else{
        this.updateSquareSubscription();
      }
      }
      
  }

  navigate(){
    window.open("https://www.routespro.com/pricing", "_blank");
  }



  updateSquareSubscription(){
    const params={
      "subscription_id": +this.selected_subscription_id,
      "billing_cycle_id": +this.selected_billing_id,
      "user_count": +this.numberOfUser,
      "square_subscription_id":this._authJwtService.shared.data.square_subscription_id==null?'':this._authJwtService.shared.data.square_subscription_id,
      "square_location_id":this.location_id
    }
    this._authJwtService.updateSubscription(params).subscribe(res=>{
      if(res.status==1){
        this._alertService.showAlert(res.message,'success');
        sessionStorage.setItem('upgrade', '1');
        this._authJwtService.callFunction.next(true);
        this._loaderservice.stop();
        this.modalRef.hide();
      }
      else{
        this._alertService.showAlert(res.message,'error');
        this._authJwtService.callFunction.next(false);
        this._loaderservice.stop();
        this.modalRef.hide();
      }
      
    },err=>{
      this._authJwtService.callFunction.next(false);
      this._loaderservice.stop();
      this.modalRef.hide();
    });
  }
  getLocationId(){
    this._authJwtService.getLocation().subscribe(res=>{
      if(res.status==1){
        this.location_id=res.square_location_id;
      }
     });
  }
  filter(event:any){
      if((event.keyCode < 48 || (event.keyCode > 57 && event.keyCode < 96) || (event.keyCode > 105) || event.shiftKey) && event.keyCode!=8){
        event.preventDefault();
      }
      if(event.target.value.length === 0 && (event.keyCode==48 || event.keyCode==96)){
        event.preventDefault();
      }
    }
}