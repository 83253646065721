import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ConfirmAlertDeleteService {

  private responseObservable;

  constructor() { }

  public confirmButton(msg: string): any {
    this.responseObservable = '';
    this.responseObservable = new Observable((observer) => {
      Swal.fire({
        title: `${msg}`,
        allowOutsideClick:false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'NO',
        confirmButtonText: 'YES',
        icon: "warning"
      }).then((result) => {
        if (result.value) {
          observer.next(true);
          observer.complete();
        } else {
          observer.next(false);
          observer.complete();
        }
      })
    })
    return this.responseObservable;
  }


  public confirmButton2(msg1: string,msg2: string): any {
    this.responseObservable = '';
    this.responseObservable = new Observable((observer) => {
      Swal.fire({
        title: `${msg1}`,
        html: msg2,
        allowOutsideClick:false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Confirm'
      }).then((result) => {
        if (result.value) {
          observer.next(true);
          observer.complete();
        }
      })
    })
    return this.responseObservable;
  }

  public cancelButton(msg: string): any {
    this.responseObservable = '';
    this.responseObservable = new Observable((observer) => {
      Swal.fire({
        title: `${msg}`,
        allowOutsideClick:false,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.value) {
          observer.next(true);
          observer.complete();
        }
      })
    })
    return this.responseObservable;
  }
}
