import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-payment-layout',
  templateUrl: './payment-layout.component.html',
  styleUrls: ['./payment-layout.component.css']
})
export class PaymentLayoutComponent implements OnInit {

  @Output() paymentNonesDetails = new EventEmitter();
  @Input() type:string;
  @Input() clientToken:any;

  public paymentTypes:Array<{}>    = [
    {
      id                          : 1,
      name                        : "Card"
    },
    {
      id                          : 2,
      name                        : "ACH"
    }
  ]

  public selectedType:{}        = {
    id                          : 0,
    name                        : ''
  }

  constructor(
    private _alertService: AlertService
  ) { }

  ngOnInit() {
    this.setSelectedType(1)
  }

  /**
   * viewDetails
   */
  public viewDetails(type) {
    
  }

  /**
   * resetSelectedType
   */
  public resetSelectedType() {
    this.selectedType             = {
      id                          : 0,
      name                        : ''
    }
  }

  /**
   * setSelectedType
   */
  public setSelectedType(id) {
    this.resetSelectedType();
    this.selectedType = this.paymentTypes.find(elm=>elm['id'] == id);
    console.log(this.selectedType)
  }
  
  /**
   * onSubmit
   */
  public onSubmit(data:NgForm) {
    console.log(data)
  }
  
}