import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../../environments/environment";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceHeaderService {

  public APIEndpoint = environment.baseUrl;
  public sub=new Subject();
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Method         : getjwtToken
   * Description    : Get token
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public getjwtToken() {
    return this.http.get(this.APIEndpoint+"api/auth/user")
    .pipe(map((response:Response) => {
      let responseAny : any = response; 
      return responseAny;
    }),
    catchError((err:any) =>{
      throw(err)
    })
    );
  }

  /**
   * Method         : globalCustomerSearch
   * Description    : Globel Customer Search
   * Date           : 2019-03-01
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public globalCustomerSearch(params) {
    return this.http.post(this.APIEndpoint+"api/auth/customer/customer-list", params)
    .pipe(map((response:Response) => {
      let responseAny : any = response; 
      return responseAny;
    }),
    catchError((err:any) =>{
      throw(err)
    })
    );  
  }

  public GetPermissionFromServer() {
    return this.http.get(this.APIEndpoint+"api/auth/access-permission-list")
    .pipe(map((response:Response) => {
      let responseAny : any = response; 
      return responseAny;
    }),
    catchError((err:any) =>{
      throw(err)
    })
    );  
  }

  public getEmployeeDetails(employeeId) {
    return this.http.get(this.APIEndpoint + 'api/auth/employee/' + employeeId)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public getActiveOfficeList() {
    const params={
      page:"",
      limit:"",
      status:"1"
    }
    let header = new HttpHeaders({
      companyId:''
    })
    return this.http.post(this.APIEndpoint + 'api/auth/user-office-list',params, { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public alertCount() {
    return this.http.post(this.APIEndpoint +"api/auth/appointment-alert-count", {})
    .pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }

  GetSubscriptionList() {
    return this.http.get(`${environment.baseUrl}api/auth/subscription-list`).pipe(map((response: Response) => {
      let res: any = response;
      return res;
    }),
      catchError((err: any) => {
        throw err
      })
    );
  }
  GetBillingCycleList(params:any){
    return this.http.post(`${environment.baseUrl}api/auth/billing-cycle-list`,params).pipe(map((response: Response) => {
      let res: any = response;
      return res;
    }),
      catchError((err: any) => {
        throw err
      })
    );
  }
  public getSubscriptionDetails() {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(this.APIEndpoint + 'api/auth/company-subscription-detail', '', { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public getBillingSubscriptionList(params:any) {
    
    return this.http.post(this.APIEndpoint + 'api/auth/billing-subscription-list', params).pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
