<!--Preloader-->
<div class="preloader-it" [class.loader]="isHidden">
  <!-- <div class="la-anim-1"></div> -->
  <div class="signal"></div>
</div>
<!--/Preloader-->
<div class="wrapper theme-2-active navbar-top-light horizontal-nav custom-overflow " id="main-page-wrapper">
  <div *ngIf="isHeaderFooterVisible()" class="hidden-print">
    <app-header (toggleEvent)="changeToggleButton($event)" *ngIf="!superAdmin"></app-header>
    <!-- <app-new-header></app-new-header> -->
    <app-super-admin-header (toggleEvent)="changeToggleButton($event)" *ngIf="superAdmin"></app-super-admin-header>
    <tour-step-template *ngIf="!superAdmin">
      <ng-template let-step="step">
        <p class="tour-step-content">{{step?.content}}</p>
        <div class="tour-step-navigation">
          <button class="btn btn-sm btn-default" *ngIf="tourService.hasNext(step)"
            (click)="tourService.end()">{{step.endBtnTitle == 'End' ? 'Skip' : ''}}</button>
          <!-- <button *ngIf="tourService.hasPrev(step)" class="btn btn-sm btn-default" (click)="tourService.prev()">«
              {{step?.prevBtnTitle}}</button> -->
          <button *ngIf="tourService.hasNext(step)" class="btn btn-sm btn-default"
            (click)="tourService.next()">{{step.nextBtnTitle}}</button>
        </div>
        <button style="float: right; margin-bottom: 10px;" *ngIf="!tourService.hasNext(step)"
          class="btn btn-sm btn-default" (click)="tourService.end(); tourFinish('finish')">Got it</button>
      </ng-template>
    </tour-step-template>
  </div>
  <div class="page-wrapper" [class.wrapper-bg]="isFullPageView()"
    [ngClass]="{'pannel-expend': toggleEnabled === true, 'pannel-closed' : toggleEnabled === false }">
    <div class="container">
      <ngx-ui-loader blur="0" delay="0" fgsColor="#1b2b47" fgsPosition="center-center" fgsSize="50"
        fgsType="fading-circle" [hasProgressBar]="false"></ngx-ui-loader>
      <router-outlet></router-outlet>
      <!-- <div *ngIf="isHeaderFooterVisible()" class="hidden-print">
        <app-footer></app-footer>
      </div> -->
    </div>
  </div>
</div>