import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
  ){}

  /**
   * validSelectedDate
   */
  public validSelectedDate(date): boolean {
    if (date !== '') {
      const todaysDate = new Date();
      const incrmentedDate = new Date(todaysDate.getTime());
      let LimitDate = incrmentedDate.getTime();
      let selectedDate = date.getTime();
      if (selectedDate >= LimitDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public getGeoJsonData() {
    return this.http.get("http://stagingsalesapp.maxroutes.com/api/customer-list-with-location/2000")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
