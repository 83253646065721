<div class="modal-body">
  <div class="row">
    <div class="col-sm-12 text-center" >
      <form>
        
        
        
        <div class="row">
          <div class="col-sm-12">
            <h3><label>Our Pricing</label></h3>
          </div>
          <div class="col-sm-12 row-gap user">
            <div class="group" style="text-align: left;">
              <label for="company_name" class="label-user">No. Of User<i class="glyphicon glyphicon-star custom-alert"></i></label>
              <input type="text" required="required" name="total_user" (keydown)="filter($event)" autocomplete="off" [(ngModel)]="numberOfUser" placeholder="Enter number of user"/>
              <!-- return /[0-9]/i.test(event.key) -->
            </div>
            <div *ngIf="selected_plan.toLowerCase()=='standard' && numberOfUser!='' && +numberOfUser>totalUserOnPlan" style="color:red;font-weight: bold;text-align: center;margin-top: 5px;">If standard plan is selected then total number of user must not exceed 100</div>
            <div *ngIf="previousUser!='' && numberOfUser!='' && +numberOfUser<+previousUser && selected_type=='annually'" style="color:red;font-weight: bold;text-align: center;margin-top: 5px;">When billing type is annual,you can't decrease no. of user</div>
            </div>
            <div class="col-sm-12 row-gap text-center">
              <ul class="nav nav-pills ">
                <li class="nav-item">
                  <a  [ngClass]="{'active': selected_type=='annually'}" (click)="changeType('annually')">Annually</a>
                </li>
                <li class="nav-item">
                  <a  [ngClass]="{'active':selected_type=='monthly'}" (click)="changeType('monthly')">Monthly</a>
                </li>
              </ul>
            
          </div>
          <div class="col-sm-12 row-gap">
            <div class="row" style="margin:20px 40px">
              <div class="col-sm-6" [ngClass]="{'active-card': item.name.toLowerCase()==selected_plan,'make-card':item.name.toLowerCase()!=selected_plan}"  *ngFor="let item of Subscription_list">
                <div class="row">
                  <div class="col-sm-12 make-bold"><h4><label>{{item.name}}</label></h4></div>
                  <div class="col-sm-12">
                    {{item.description}}
                  </div>
                  <div class="col-sm-12">
                    <img *ngIf="item.name.toLowerCase()=='standard'" src='./assets/dist/img/38.svg' width="60px" height="80px">
                    <img *ngIf="item.name.toLowerCase()=='enterprise'" src='./assets/dist/img/41.svg' width="60px" height="80px">
                  </div>
                  <div class="col-sm-12">
                    <h4><label style="color: #1b2a47;">${{item.billing_price }}/user</label></h4>
                    <div style="margin-bottom: 5px;">
                      Billed annually
                    </div>
                    
                  </div>
                  <div class="col-sm-12 setup-fee">
                    ${{item.setup_price}} One-time setup fee
                  </div>
                  <div class="col-sm-12 setup-fee">
                    <button type="button" class="btn btn-color-view-plan" (click)="navigate()">View Plan</button>
                  </div>
                  <div class="col-sm-12 setup-fee">
                    <button type="button" class="btn btn-color-view-plan" (click)="onCardClick(item.name.toLowerCase(),item)">Choose Plan</button>
                  </div>
                  </div>
                </div>
                
              </div>
            </div>
          
          


          <div class="col-sm-12 row-gap">
            <button type="button" class="btn btn-color-upgrade right-gap" (click)="onUpgrade(square)">Upgrade</button>
            <button type="button" *ngIf="is_upgrade" class="btn btn-color-close" (click)="modalRef.hide()">Skip</button>
            <button type="button" *ngIf="!is_upgrade" class="btn btn-color-close" (click)="signOut()">Sign Out</button>
          </div>
        </div>
        
       
      </form>
    </div>
  </div>
</div>

<ng-template #square>
  <app-square-layout [modalRef_square]="modalRef_square" [modalRef]="modalRef" [plan]="plan" [selected_billing_id]="selected_billing_id" [selected_subscription_id]="selected_subscription_id" [numberOfUser]="numberOfUser" [location_id]="location_id"></app-square-layout>
</ng-template>


