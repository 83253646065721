import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';

import { LoaderService } from '../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-delete-popup',
    templateUrl: './delete-popup.html',
    styleUrls: ['./delete-popup.css']
})
export class DeletePopup implements OnInit {

    public dataSub: Subscription;

    @Input() deleteObj: any;
    @Output() deleteConfirmationMsg = new EventEmitter();

    public map: any;
    public location: any

    constructor(
        private _loaderService: LoaderService,
    ) { }

    ngOnInit() {
        console.log(this.deleteObj);
    }

    handleConfirm(value: any) {

        this.deleteConfirmationMsg.emit({ status: value, id: this.deleteObj.id ,modalType:this.deleteObj.modalType,leadData : this.deleteObj.leadData});
    }

}
