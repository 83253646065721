import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

/* npm */
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
// import { EmbedVideo } from 'ngx-embed-video';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

/* App Component and App Routing  */
import { LayoutComponent } from './layout.component';
import { AppRoutingModule } from './layout.routing';

/* Component */
import { HeaderComponent } from './common/header/header.component';
// import { FooterComponent } from './common/footer/footer.component';
import { UpgradeNowComponent } from './common/upgrade-now/upgrade-now.component';

/* Modules */
import { SharedModule } from "./shared/shared.module";
import { UsersModule } from "./users/users.module";

/* Services */
import { LoaderService } from "./shared/services/loader.service";
import { AuthJwtService } from "./shared/services/auth-jwt.service";
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { LogoutService } from "./shared/services/logout.service";
// import { DemoComponent } from './common/demo/demo.component';
// import { Demo2Component } from './common/demo2/demo2.component';
// import { NgxBootstrapProductTourModule } from 'ngx-bootstrap-product-tour';
import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';
// import { NewHeaderComponent } from './common/new-header/new-header.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ClickOutsideModule } from 'ng4-click-outside';
import { SuperAdminHeaderComponent } from './common/super-admin-header/header.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    // FooterComponent,
    // DemoComponent,
    // Demo2Component,
    // NewHeaderComponent,
    SuperAdminHeaderComponent,
    UpgradeNowComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,

    ModalModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UsersModule,
    // EmbedVideo.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),

    TourNgxBootstrapModule.forRoot(),
    NgSelectModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    ClickOutsideModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    LoaderService,
    AuthJwtService,
    CookieService,
    LogoutService,
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe
  ],
  exports: [

  ],
  bootstrap: [LayoutComponent]
})
export class LayoutModule { }
