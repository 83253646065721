import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
// import 'rxjs/add/operator/filter';
import { LoaderService } from "./shared/services/loader.service";
import { TourService } from 'ngx-tour-ngx-bootstrap';
// import { DashboardService } from './admin/dashboard-layout/_services/dashboard.service';
import { AuthJwtService } from './shared/services/auth-jwt.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public currentRoute = '';
  public currentRoute1 :any;
  public isHidden: boolean = false;
  superAdmin=false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _loaderService: LoaderService,
    public tourService: TourService,
    private _authJwtService: AuthJwtService
  ) { }

  ngOnInit() {
    this.router.events
      .subscribe(event => {
        let currentRoute = this.route.root;
        this.currentRoute1 = this.router.url;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.currentRoute = currentRoute.url['_value'][0]['path'];
        if (this.currentRoute == '' || this.currentRoute == 'login') {
          this.toggleEnabled = false;
          this.superAdmin=localStorage.getItem('_isAdmin')=='1'?true:false;
        }
        // console.log(this.currentRoute);
      });

    // this._dashboardService.bSubject.subscribe(res=>{
    //   console.log(res);
    //   if(res == '1'){
    //     this.tourService.initialize(
    //       [{
    //         anchorId: '/employee/list',
    //         content: 'Thanks to angular-ui-tour for the inspiration for the library',
    //         placement: 'bottom',
    //         title: 'Employee'
    //       },
    //         {
    //           anchorId: '/customers/customers-list',
    //           content: 'Welcome to the Ngx-Tour tour!',
    //           placement: 'bottom',
    //           title: 'Customer'
    //         }, {
    //           anchorId: '/office/office-list',
    //           content: 'Welcome to the Ngx-Tour tour!',
    //           placement: 'top',
    //           title: 'Location'
    //         }
    //       ]
    //     );
    //     this.tourService.start();
    //   }
    // })

    this._loaderService.castLoader.subscribe(loader => this.loader(loader));
  }

  /**
   * loader
   */
  public loader(data) {
    if (data === 'showloader') {
      this.isHidden = true;
    } else if (data === 'hideloader') {
      this.isHidden = false;
    }
  }

  isHeaderFooterVisible = (): boolean => {
    if(this.currentRoute1?.includes('based')) return true
    if (this.currentRoute == '') return false
    if (this.currentRoute == 'login') return false
    if (this.currentRoute == 'confirm-Email') return false
    if (this.currentRoute == 'signup-form') return false
    if (this.currentRoute == 'otp-verify') return false
    if (this.currentRoute == 'new-password') return false
    if (this.currentRoute == 'location-track') return false
    if (this.currentRoute == 'serviced-images') return false
    if (this.currentRoute == 'privacy-policy') return false
    if (this.currentRoute == 'terms-and-conditions') return false
    return true
  }

  isFullPageView = (): boolean => {
    if (this.currentRoute === 'login') return true
    if (this.currentRoute === 'confirm-Email') return true
    if (this.currentRoute == 'signup-form') return true
    if (this.currentRoute == 'otp-verify') return true
    if (this.currentRoute == 'new-password') return true
    if (this.currentRoute == 'location-track') return true
    if (this.currentRoute == 'serviced-images') return true
    if (this.currentRoute == 'privacy-policy') return true
    if (this.currentRoute == 'terms-and-conditions') return true
    return false
  }

  tourFinish(string) {
    this._authJwtService.newBSubject.next(string);
  }

  toggleEnabled = false;
  changeToggleButton(event) {
    this.toggleEnabled = event;
  }
}
