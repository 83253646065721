import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { AuthPermissionGuardService } from './shared/services/auth-permission-guard.service';
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { CustomPreloadingService } from './shared/services/custom-preloading.service';

/* Components */
// import { DemoComponent } from './common/demo/demo.component';
// import { Demo2Component } from './common/demo2/demo2.component';


const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login-layout/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        redirectTo: 'login-layout/login',
        pathMatch: 'full'
    },
    {
        path: 'login-layout',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'super-admin',
        loadChildren: () => import('./login-super-admin/login-super-admin.module').then(m => m.SuperAdminLoginModule)
    },
    {
        path: 'privacy-policy-layout',
        loadChildren: () => import('./PrivacyPolicy/Privacy-Policy.module').then(m => m.PrivacyPolicyModule)
    },
    {
        path: 'terms-and-conditions-layout',
        loadChildren: () => import('./TermsAndCondition/Terms-Conditions.module').then(m => m.TermsComponentModule)
    },
    {
        path: 'super-admin-report-layout',
        loadChildren: () => import('./admin-super/report-layout/super-admin-report.module').then(m => m.SuperAdminReportModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'dashboard-layout',
        loadChildren: () => import('./admin/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
        data : { preload:true },
        canActivate: [AuthGuardService]
    },
    // {
    //     path: 'hires',
    //     loadChildren: './admin/hires-layout/hires-layout.module#HiresLayoutModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'employee',
        loadChildren: () => import('./admin/employee-layout/employee-layout.module').then(m => m.EmployeeLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['employee_list']
        }
    },
    {
        path: 'budget',
        loadChildren: () => import('./admin/budget-layout/budget-layout.module').then(m => m.BudgetLayoutModule),
        canActivate: [AuthGuardService]        
    },
    // {
    //     path: 'zone',
    //     loadChildren: './admin/zone-layout/zone-layout.module#ZoneLayoutModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'office',
        loadChildren: () => import('./admin/office-layout/office-layout.module').then(m => m.OfficeLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['office_list']
        }
    },
    {
        path: 'team',
        loadChildren: () => import('./admin/team-layout/team-layout.module').then(m => m.TeamLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['team_list']
        }
    },
    {
        path: 'inventory',
        loadChildren: () => import('./admin/inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'service',
        loadChildren: () => import('./admin/service-type-layout/service-type-layout.module').then(m => m.ServiceTypeLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['service_list']
        }
    },
    {
        path: 'report',
        loadChildren: () => import('./admin/report-layout/report-layout.module').then(m => m.ReportLayoutModule),
        canActivate: [AuthGuardService],
        data:{
            role:['report_list','invoice_list','sales_report']
        }
    },
    {
        path: 'customer-report',
        loadChildren: () => import('./admin/customer-report-layout/customer-report-layout.module').then(m => m.CustomerReportLayoutModule),
        canActivate: [AuthGuardService],
        data:{
            role:['customer-report']
        }
    },
    // {
    //     path: 'blackout-zone',
    //     loadChildren: './admin/blackout-zone/blackout-zone.module#BlackoutZoneModule',
    //     canActivate: [AuthGuardService]
    // },
    // {
    //     path: 'sales-zone',
    //     loadChildren: './admin/sales-zone/sales-zone.module#SalesZoneModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'routes',
        loadChildren: () => import('./admin/routes-layout/routes-layout.module').then(m => m.RoutesLayoutModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers',
        loadChildren: () => import('./admin/customer-layout/customer-layout.module').then(m => m.CustomerLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['customer_list']
        }
    },
    {
        path: 'recruit',
        loadChildren: () => import('./admin/recruit-layout/recruit-layout.module').then(m => m.RecruitLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['customer_list']
        }
    },
    {
        path: 'technicians',
        loadChildren: () => import('./admin/technicians-routes/technicians-routes.module').then(m => m.TechniciansRoutesModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['assign_route']
        }
    },
    {
        path: 'setting-layout',
        loadChildren: () => import('./admin/setting-layout/setting-layout.module').then(m => m.SettingLayoutModule),
        canActivate: [AuthGuardService]
    },
    // {
    //     path: 'lead-layout',
    //     loadChildren: './admin/lead-layout/lead-layout.module#LeadLayoutModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'tutorial-layout',
        loadChildren: () => import('./admin/tutorials-layout/tutorials-layout.module').then(m => m.TutorialsLayoutModule),
        canActivate: [AuthGuardService]
    },
    // {
    //     path: 'cus-report',
    //     loadChildren: './admin/customer-report/customer-report.module#CustomerReportModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'map',
        loadChildren: () => import('./admin/map-routes/map-routes.module').then(m => m.MapRoutesModule),
        canActivate: [AuthGuardService],
        data:{
            role:['route_map','existing_route','existing_route']
        }
    },
    {
        path: 'coupon',
        loadChildren: () => import('./admin/coupon-layout/coupon-layout.module').then(m => m.CouponLayoutModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['coupon_list']
        }
    },
    {
        path: 'tracker-map',
        loadChildren: () => import('./admin/tracker-map/tracker-map.module').then(m => m.TrackermapModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['tracker_list']
        }
    },
    {
        path: 'salesmap',
        loadChildren: () => import('./admin/map-sales/map-sales.module').then(m => m.MapSalesModule),
        canActivate: [AuthGuardService,AuthPermissionGuardService],
        data:{
            role:['polygon_add','polygon_update','polygon_delete','pin_add','pin_update','pin_delete']
        }
    },
    // {
    //     path: 'tournament',
    //     loadChildren: './admin/tournament/tournament.module#TournamentModule',
    //     canActivate: [AuthGuardService]
    // },
    // {
    //     path: 'notifications',
    //     loadChildren: './admin/notification/notification.module#NotificationModule',
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'forget-password',
        loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordModule),
    },
    {
        path: 'signup-layout',
        loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    },
    {
        path: 'location-layout',
        loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule),
    },
    {
        path: '',
        loadChildren: () => import('./image-details/view-image.module').then(m => m.ViewImageModule),
    },

    // { path: 'demo', component: DemoComponent },
    // { path: 'demo2', component: Demo2Component },
    // {
    //     path: 'logs',
    //     loadChildren: './admin/log-layout/log-layout.module#LogLayoutModule',
    //     canActivate: [AuthGuardService]
    // }
];




@NgModule({
    imports: [RouterModule.forRoot(appRoutes,{ preloadingStrategy: CustomPreloadingService, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }