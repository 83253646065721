import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
  ) { }

  /**
   * Method         : LogOut
   * Description    : Logged out from App
   * Date           : 2018-11-22
   * Author         : Satyabrata Rakshit(Appsbee)
   */
  public LogOut() {
    sessionStorage.removeItem('_authToken');
    localStorage.removeItem('_token');
    localStorage.removeItem('_usertoken');
    localStorage.removeItem('_name');
    // this._router.navigate(['login']);
    // this._router.navigate(['']);
    if(localStorage.getItem('_isAdmin')=='1'){
      window.location.replace("#/super-admin/login");
      window.location.reload();
    }else{
      window.location.replace("");
    }
    
  }
}
