/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  // aws_project_region: "us-east-2",
  // aws_cognito_region: "us-east-2",
  // aws_user_pools_id: "us-east-2_ctktfz0tL",
  // aws_user_pools_web_client_id: "60f8327nnp963ffpgohlvb75r0",
  aws_project_region: "us-west-1",
  aws_cognito_region: "us-west-1",
  // aws_user_pools_id: "us-west-1_rpEKtcsPC",
  // aws_user_pools_web_client_id: "6ha2s4im0gksnm152dm7vgt2rg",
  aws_user_pools_id: "us-west-1_CAIRCz7a6",
  aws_user_pools_web_client_id: "2ep8gr098jo4014ncfok7qkfn3",
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "explore.tracker": { // REQUIRED - Amazon Location Service Map resource name
            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
          },
        },
        default: "explore.tracker", // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      // region: 'us-east-2', // REQUIRED - Amazon Location Service Region
      region: 'us-west-1', // REQUIRED - Amazon Location Service Region
    },
  },
};

export default awsmobile;
