import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LogoutService } from './logout.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  public token: string;
  public userToken:string;

  constructor(
    private _logoutService: LogoutService,
    private _alertService: AlertService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    this.token          = this.getAccessToken();
    this.userToken      = this.getUserToken();

    let _req:any;
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let vimeo = /\bvimeo/gi;
    let isVimeo = req.url.match(vimeo);
    let prediction = req.url.match(/\bpredict_comment/gi);
    if(isVimeo !== null && isVimeo.length > 0){
      _req = req.clone({
        setHeaders: {
        }
      });
    }else if(prediction !== null && prediction.length > 0){
      _req = req.clone({
        setHeaders: {
          "Content-Type": "application/json"
        }
      });
    }else{
      _req = req.clone({
        setHeaders: {
          Authorization: this.token == '' ? this.token : this.token,
          officeid: this.userToken == '' ?  this.userToken :  this.userToken,
          companyId:''
        }
      });
    }
    // send cloned request with header to the next handler.
    return next.handle(_req)
    .pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
  
          }
        },
        error => {
          if(error instanceof HttpErrorResponse){
            if(error.status == 0){
              console.log(error);
            }else if(error.status == 401){
              console.error(error.statusText);
              this._logoutService.LogOut();
              this._alertService.showAlert('Unauthorized Request, Please Login', 'error');
            }else if(error.status == 404 && isVimeo !== null && isVimeo.length > 0){
              // this._alertService.showAlert('Image Not Found!!', 'info');
            }else{
              this._alertService.showAlert(error.statusText, 'error');
            }
          }
        }
      )
    );
  }

  private getAccessToken(): string {
    if (sessionStorage.getItem('_authToken')) {
      const authToken = sessionStorage.getItem('_authToken');
      if (authToken !== '') {
        return authToken;
      }else{
        const authToken = '';
        return authToken;
      }
    } else {
      const authToken = '';
      return authToken;
    }
  }

  private getUserToken(): string {
    if(localStorage.getItem('_usertoken') !== null){
      const userToken = atob(localStorage.getItem('_usertoken'));
      return userToken;
    }else{
      const userToken = '';
      return userToken;
    }
  }
}