import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthJwtService } from '../../services/auth-jwt.service';
declare var google: any;

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent implements OnInit {


  @Input() idName;
  @Input() dataValue;
  @Input() type=null;
  @Input() resetpieSubject: Subject<any> = new Subject<any>();

  constructor(private _authJwtService:AuthJwtService) {
    google.charts.load('42', {
      'packages': ['corechart']
    });
    this._authJwtService.chartResize.subscribe(res=>{
      this.drawGraph();
    });
   }

  ngOnInit() {
    /* =====Chart Init===== */
    // google.charts.load('42', {
    //   'packages': ['corechart']
    // });
    const that=this;
    $(window).resize(function(){
      that.drawGraph();
    });
    this.start();
    this.resetpieSubject.subscribe(response => {
      this.dataValue=response.data;
      this.idName=response.name;
      this.type=response.type;
      this.start();
    });
  }

  /**
   * start
   */
  public start() {
    google.charts.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  // public drawGraph() {
  //   const rawData = [...this.dataValue];
  //   if(this.type == 'Customer Source Types'){
  //     rawData.unshift(['Task', 'Customer Source Types']);
  //     this.data = new google.visualization.arrayToDataTable(rawData);
  //   }else if(this.type == 'Appoinment Status'){
  //     rawData.unshift(['Task', 'Appoinment Status']);
  //     this.data = new google.visualization.arrayToDataTable(rawData);
  //   }else{
  //     this.data = new google.visualization.DataTable();
  //     this.data.addColumn('string', 'CustomerStatus');
  //     this.data.addColumn('number', 'Number');
  //     this.data.addRows(rawData);
  //   }

  //   if(this.type == 'Employee Route Assign'){
  //     this.options = {
  //       is3D: true,
  //       chartArea:{left:0,top:0,width:'100%',height:'90%'},
  //       legend: {position: 'bottom', alignment:'center'},
  //       sliceVisibilityThreshold: 0.0000001,
  //       tooltip: { trigger: 'selection' },
  //       colors: this.ColorArray(this.rawColorArray),
  //     };
  //   }else if(this.type == 'Customer Source Types' || this.type == 'Appoinment Status'){
  //     this.options = {
  //       pieHole: 0.4,
  //       chartArea:{left:0,top:10,width:'100%',height:'80%'},
  //       legend: {position: 'bottom', alignment:'center'},
  //       sliceVisibilityThreshold: 0.0000001,
  //       tooltip: { trigger: 'selection' }
  //     };
  //   }else if(this.type == 'Customer Status'){
  //     this.options = {
  //       chartArea: {width: '90%', height:'80%'},
  //       legend: {
  //         position: 'right', 
  //         alignment:'top'},
  //       sliceVisibilityThreshold: 0.0000001,
  //       tooltip: { trigger: 'selection' },
  //       colors: ["#0072A7","#24B8FD"]
  //     };
  //   }else{
  //     this.options = {
  //       is3D: true,
  //       chartArea:{left:0,top:0,width:'100%',height:'90%'},
  //       legend: { position: 'bottom', alignment:'center'},
  //       sliceVisibilityThreshold: 0.0000001,
  //       tooltip: { trigger: 'selection' },
  //     };
  //   }

  //   this.chart = new google.visualization.PieChart(document.getElementById(this.idName));
  //   this.chart.draw(this.data, this.options);

  // }

  /**
   * ColorArray
   */
  public ColorArray(item):[] {
    let itemSplit = item.split(',');
    return itemSplit;
  }


  drawGraph(){
    var data = google.visualization.arrayToDataTable(this.dataValue)
    var options = {
      legend: (this.type=="first_pie" || this.dataValue.length==2)?'none':{position: 'labeled',textStyle: {bold: true}},
      pieSliceText: (this.type=="first_pie" || this.dataValue.length==2)?'label':'none',
      colors: ["#808080","#1b2b47","rgb(195, 194, 194)","#073763"],
      fontSize:12,
      backgroundColor: {
        fillOpacity: 0
    },
    chartArea: {left:10,
      right:10,
      top:(this.type=="first_pie" || this.dataValue.length==2)?40:50,
      width: '95%', 
      height: '80%'},
    tooltip : {
        trigger: this.dataValue.length==2?'none':''
      },
    }
    const container=document.getElementById(this.idName);
    if(container!=undefined){
      var chart = new google.visualization.PieChart(container);
      chart.draw(data, options);
    }
    
  }

  

}
