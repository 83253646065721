// import { HiringService } from './../../../admin/hires/hires.service';
import { Component, OnInit, Input, OnDestroy, SimpleChanges } from '@angular/core';

declare let google: any;

@Component({
  selector: 'app-column-chart1',
  templateUrl: './column-chart1.component.html',
  styleUrls: ['./column-chart1.component.css']
})
export class ColumnChartRecruitingComponent implements OnInit {

  @Input() idName;
  @Input() dataValue;
  @Input() type;
  spinner: boolean = false;
  // @Input() rawColorArray;

  constructor() { }

  ngOnInit() {
    /* =====Chart Init===== */
    // google.charts.load('current', {
    //   'packages': ['corechart']
    // });
    // this.start();

    // this._hiringService.chartBSubject.subscribe(res => {
    //   if (res == 'success') {
      this.spinner = true;
        google.charts.load('current', {
          'packages': ['corechart']
        });
        // this.start();

    //   }
    // });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.spinner = true;
    if (changes['dataValue'] && changes['dataValue'].currentValue) {
      this.start();
    }
  }

  /**
   * start
   */
  public start() {
    google.charts?.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  public drawGraph() {
    const rawData = [...this.dataValue];
    // console.log(rawData);
    rawData.map((elm) => {
      if (this.type == 'Payment Types') {
        if (elm[0] == 'Cash') {
          elm.push('#0072A7');
        } else if (elm[0] == 'Card') {
          elm.push('#0092D6');
        } else if (elm[0] == 'ACH') {
          elm.push('#24B8FD');
        } else {
          elm.push('#1f8ceb');
        }
      } else {
        elm.push('#324148');
      }
    });

    let title = "";
    let custHeight;
    if (this.type == 'Employee Route Assign') {
      rawData.unshift(['Employee', 'Employee Route Assign', { role: "style" }]);
      title = "Count";
      custHeight = 300;
    } else if (this.type == 'Appoinment Status') {
      rawData.unshift(['Count', 'Appoinment Status', { role: "style" }]);
      title = "Appoinment Status";
      custHeight = "100%"
    }
    else if (this.type == 'Recruiting Stats') {
      rawData.unshift(['Count', 'Recruiting Stats', { role: "style" }]);
      title = "Recruiting Stats";
      custHeight = "500"
    }
    else if (this.type == 'Attendance') {
      rawData.unshift(['Count', 'Attendance', { role: "style" }]);
      title = "Attendance";
      custHeight = "300"
    }
    else if (this.type == 'Payment Types') {
      rawData.unshift(['Count', 'Payment Types', { role: "style" }]);
      title = "Payment Types( % )";
      custHeight = "100%"
    }
    const data = new google.visualization.arrayToDataTable(rawData);
    let view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    let options = {
      width: "90%",
      height: custHeight,
      chartArea: { width: '80%' },
      bar: { groupWidth: "95%" },
      legend: { position: "none" },
      vAxis: {
        title: title,
        minValue: 0
      },
      tooltip: { textStyle: { color: '#000' }, showColorCode: true, ignoreBounds: true }
    };
    // setTimeout(() => {
      // }, 100);
      
      setTimeout(() => {
        let chart = new google.visualization.ColumnChart(document.getElementById(this.idName));
        chart.draw(view, options);
        this.spinner = false;
    }, 100);
  }

  //   /**
  //  * ColorArray
  //  */
  // public ColorArray(item):[] {
  //   let itemSplit = item.split(',');
  //   return itemSplit;
  // }
  ngOnDestroy(): void {
    this.dataValue = [];
  }

}
