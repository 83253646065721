import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private _logOutService: LogoutService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('_token') !== null) 
    // return this.isAuthorized(state);
    return true;
    this._logOutService.LogOut();
    return false;
  }

  isAuthorized = (state: RouterStateSnapshot) :boolean => {
      // const userDetails = this._authJwtService.decodeAuthToken(localStorage.getItem('_token'));
      // if(userDetails.user_group_id == 3 || userDetails.user_group_id == 4){
      //   if(state.url.includes('tutorial-layout')) return true
      //   this._alertService.showAlert('No Privilege', 'info')
      //   this._router.navigate(['tutorial-layout/tutorials-list']);
      //   return false
      // }else{
        //shayan
        return true
      // }
  }

  
}
