import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/* npm */
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

/* Components */
import { PiechartComponent } from './charts/piechart/piechart.component';
// import { AreachartComponent } from './charts/areachart/areachart.component';
import { NumberOnlyDirective } from './directives/allow-number-only/number-only.directive';
import { PhoneFormatDirective } from './directives/us-phone-format/phone-format.directive';
import { SquareLayoutComponent } from './square-layout/square.component';
import { HighlightPipe } from './directives/search-highlight/highlight.pipe';
import { DropdownDirective } from "./directives/dropdown.directive";
import { ColumnChartComponent } from './charts/column-chart/column-chart.component';
// import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
// import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BraintreeLayoutComponent } from './braintree-layout/braintree-layout.component';
import { PaymentLayoutComponent } from "./payment-layout/payment-layout.component";
import { MapViewComponent } from './map-view/map-view.component';
import { MobileNumberDirective } from './directives/mobile-number.directive';
import { IntegerOnlyDirective } from './directives/integer-only.directive';
import { RoutesIQDateFormaterPipe } from './routes-iq-date-formater.pipe';
/* Services */
import { DeletePopup } from './deleteConfirmation/delete-popup';
import { ColumnChartRecruitingComponent } from './charts/column-chart1/column-chart1.component';
// import {NgxPaginationModule} from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TwoDigitDecimaNumberDirective } from './directives/two-decimal-places.directive';
@NgModule({
  declarations: [
    PiechartComponent,
    // AreachartComponent,
    NumberOnlyDirective,
    HighlightPipe,
    DropdownDirective,
    ColumnChartComponent,
    // BarChartComponent,
    // LineChartComponent,
    PhoneFormatDirective,
    BraintreeLayoutComponent,
    PaymentLayoutComponent,
    MapViewComponent,
    MobileNumberDirective,
    IntegerOnlyDirective,
    RoutesIQDateFormaterPipe,
    DeletePopup,
    SquareLayoutComponent,
    // AddEmployeeComponent
    ColumnChartRecruitingComponent,
    TwoDigitDecimaNumberDirective
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  providers: [],
  exports: [
    PiechartComponent,
    CommonModule,
    FormsModule,
    // AreachartComponent,
    NumberOnlyDirective,
    HighlightPipe,
    ColumnChartComponent,
    NgSelectModule,
    // BarChartComponent,
    // LineChartComponent,
    PhoneFormatDirective,
    BraintreeLayoutComponent,
    MapViewComponent,
    RoutesIQDateFormaterPipe,
    // AddEmployeeComponent,
    MobileNumberDirective,
    DeletePopup,
    SquareLayoutComponent,
    ColumnChartRecruitingComponent,
    TwoDigitDecimaNumberDirective
  ]
})
export class SharedModule { }
