<nav class="navbar navbar-inverse navbar-fixed-top navigationBar attop">
  <div class="nav-wrap">
    <div class="mobile-only-brand pull-left" style="height:50px;width:55px;">

      <a id="toggle_nav_btn" class=" toggleNavBtn toggle-left-nav-btn inline-block ml-20 pull-left"
        href="javascript:void(0);" (click)="navToggle()"><i class="zmdi zmdi-menu"></i>
      </a>
    </div>
    <div class="nav-cantaintent-right">
      <div class="log-section">
        <a [routerLink]="['/super-admin-report-layout/all-report']" routerLinkActive="active">
          <span class="brand-text"><img class="logo-wrapper" src="../../../../assets/dist/img/R_logo1 (1).png"
              alt="Element Pestsolution" height="30px" /></span>
        </a>
      </div>

      <div class="signout-section">
        <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true" style="margin-top:5px;">
          <div style="cursor: pointer;" id="button-basic" dropdownToggle class="dropdown-toggle"
            aria-controls="dropdown-basic">
            <img class="signout-profile"
              [src]="employeeDetails?.pic==null||employeeDetails?.pic==undefined?'../../../../assets/dist/img/person-icon.png':employeeDetails.pic">
            <div class="profile-name-section">
              <p class="profile-name">{{employeeDetails?.fname | uppercase}} {{employeeDetails?.lname | uppercase}}</p>
              <p class="profile-sub-name">{{employeeDetails?.user_group_name}}</p>
            </div>
            <div class="dropdown-arrow"><span style="color: #fff;" class="caret"></span></div>
          </div>

          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">

            <li role="menuitem"><a class="dropdown-item " style="cursor:pointer" (click)="signOut()"><i
                  class="fa fa-sign-out"></i><strong>Sign Out</strong></a>
            </li>
          </ul>
        </div>
      </div>
    </div>



  </div>
</nav>
<div class="fixed-sidebar-left">
  <ul class="nav navbar-nav side-nav nicescroll-bar mt-4">
    <li>
      <a [routerLink]="['/super-admin-report-layout/all-report']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper">
            <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/report.png" alt="" />
            </div>
          </div><span class="right-nav-text">All Reports</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <!-- <li>
      <a [routerLink]="['/super-admin-report-layout/payment-report']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper">
            <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/report.png" alt="" />
            </div>
          </div><span class="right-nav-text">Payment Report</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li> -->
  </ul>
</div>