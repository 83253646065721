<div class="modal-header" style="display: none;">
    <h4 class="modal-title pull-left">{{deleteObj.title}}</h4>
</div>
<div class="modal-body text-center" style="text-align : center">
    <i [class]="deleteObj.type=='delete' ? 'fa fa-exclamation-circle fa-exclamation-circle1' : 'fa fa-exclamation-circle'" style="font-size:100px"></i>

   <h3> {{deleteObj.body}}</h3>
</div>
<div class="modal-footer text-center" style="text-align : center">
    <button type="button" class="btn btn-default" (click)="handleConfirm(0)">No</button>
    <button type="button" class="btn btn-dark" (click)="handleConfirm(1)"
        *ngIf="deleteObj.type=='delete'">Yes</button>
 <button type="button" class="btn btn-dark" (click)="handleConfirm(1)"
        *ngIf="deleteObj.type!='delete'">Yes</button>
</div>