import { Component, OnInit, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, } from '@angular/router';
import { LoaderService } from "../../shared/services/loader.service";
import { ConfirmAlertDeleteService } from '../../shared/services/confirm-alert-delete.service';
import { Subscription } from 'rxjs';
import { LogoutService } from '../../shared/services/logout.service';
import { AuthJwtService } from '../../shared/services/auth-jwt.service';
import { ServiceHeaderService } from '../header/_services/service-header.service';
@Component({
  selector: 'app-super-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class SuperAdminHeaderComponent implements OnInit,AfterViewInit{

  @Output() toggleEvent = new EventEmitter;
  @Output() office = new EventEmitter();
  public currentPath = '';
  public resource: any;
  public employeeDetails: any;
  constructor(
    public _ConfirmAlertDeleteService: ConfirmAlertDeleteService,
    private _loaderService:LoaderService,
    private _router:Router,
    private route:ActivatedRoute,
    private elementRef:ElementRef,
    private _logoutService:LogoutService,
    private _authJwtService:AuthJwtService,
    private _serviceHeaderService:ServiceHeaderService
  ) {

  }

  ngOnInit() {
    this.getResourceDetails();
    this._router.events
      .subscribe(event => {
        let currentRoute = this.route.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.currentPath = currentRoute.url['_value'][0]['path'];


        /* On Navigation Route Loader Is Enabled  */
        switch (true) {
          case event instanceof NavigationStart: {
            this._loaderService.showLoader();
            break;
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this._loaderService.hideLoader();
            break;
          }
          case event instanceof NavigationEnd: {
            break;
          }
          default: {
            break;
          }
        }
      });
    if (this.currentPath == '')
      this.currentPath = this.route.root.children[0].url['_value'][0]['path'];
  }
  toggleEnabled = false;
  navToggle() {
    this.toggleEnabled = !this.toggleEnabled;
    this.toggleEvent.emit(this.toggleEnabled);
  }
  ngAfterload() {
    var s3 = document.createElement("script");
    s3.type = "text/javascript";
    s3.src = "./assets/vendors/bower_components/bootstrap/dist/js/bootstrap.min.js";
    this.elementRef.nativeElement.appendChild(s3);

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "./assets/dist/js/jquery.slimscroll.js";
    this.elementRef.nativeElement.appendChild(s);

    var s1 = document.createElement("script");
    s1.type = "text/javascript";
    s1.src = "./assets/dist/js/init.js";
    this.elementRef.nativeElement.appendChild(s1);

    var s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.src = "./assets/custom/RoutesPro.js";
    this.elementRef.nativeElement.appendChild(s2);
  }

  ngAfterViewInit() {
    this.ngAfterload();
  }
  public signOut() {
    
    this._ConfirmAlertDeleteService.confirmButton("Do you want to sign out?").subscribe(
      (resp) => {
        if (resp) {
          this._loaderService.showLoader();
          this._logoutService.LogOut();
        }
      }
    );
  }
  public getResourceDetails() {
    if (localStorage.getItem('_token') !== null) {
      const rawToken = localStorage.getItem('_token');
      this.resource = this._authJwtService.decodeAuthToken(rawToken);
      this._serviceHeaderService.getEmployeeDetails(this.resource.id).subscribe((data) => {
        if (data.success == 1) {
          this.employeeDetails = data.employee_det;
          
        } else {

        }
      },
        (error) => {
          console.log(error);
        })
    }
  }
}
