<!-- Top Menu Items -->
<nav class="navbar navbar-inverse navbar-fixed-top navigationBar attop">
  <div class="nav-wrap">
    <div class="mobile-only-brand pull-left" style="height:50px;width:55px;">

      <a id="toggle_nav_btn" class=" toggleNavBtn toggle-left-nav-btn inline-block ml-20 pull-left"
        href="javascript:void(0);" (click)="navToggle()"><i class="zmdi zmdi-menu"></i>
      </a>
      <!-- <a id="toggle_mobile_nav" class="mobile-only-view" href="javascript:void(0);"><i class="zmdi zmdi-more"></i></a> -->
    </div>
    <div class="nav-cantaintent-right">
      <div class="log-section">
        <a [routerLink]="['/dashboard-layout/dashboard']">
          <span class="brand-text"><img class="logo-wrapper" src="../../../../assets/dist/img/R_logo1 (1).png"
              alt="Element Pestsolution" height="30px" /></span>
        </a>
      </div>
      <!-- <div class="upgrade-container" *ngIf="(subscription_error=='' && is_upgrade==0);else content">
        <p *ngIf="subscription_days>0">Your free trial expires in <strong>{{subscription_days}} day(s).</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days==0">Your free trial will expires <strong>today.</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days<0">Your free trial has <strong>expired.</strong>
          <span class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
      </div>
      <ng-template #content>
        <span class="btn-position" (click)="UpgradeModal(upgradenow)">Upgrade
          Now</span>
      </ng-template> -->


      <div class="upgrade-container" *ngIf="(resource.is_own_company=='1' && subscription_error=='' && is_upgrade==0)">
        <p *ngIf="subscription_days>0">Your free trial expires in <strong>{{subscription_days}} day(s).</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days==0">Your free trial will expires <strong>today.</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days<0">Your free trial has <strong>expired.</strong>
          <span class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
      </div>
      <div class="upgrade-container" *ngIf="(resource.is_own_company=='1' && subscription_error=='' && is_upgrade==1)">
        <p *ngIf="subscription_days>0">Your subscription expires in <strong>{{subscription_days}} day(s).</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days==0">Your subscription will expires <strong>today.</strong>
          <span
            class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
        <p *ngIf="subscription_days<0">Your subscription has <strong>expired.</strong>
          <span class="btn-upgrade" (click)="UpgradeModal(upgradenow)">Upgrade
            Now</span>
        </p>
      </div>
      <!-- <ng-template #content>
        <span class="btn-position" (click)="UpgradeModal(upgradenow)">Upgrade
          Now</span>
      </ng-template> -->
      
      <div class="signout-section">
        <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true" style="margin-top:5px;">
          <div style="cursor: pointer;" id="button-basic" dropdownToggle class="dropdown-toggle"
            aria-controls="dropdown-basic">
            <img class="signout-profile"
              [src]="employeeDetails?.pic==null||employeeDetails?.pic==undefined?'../../../../assets/dist/img/person-icon.png':employeeDetails.pic">
            <div class="profile-name-section">
              <p class="profile-name">{{employeeDetails?.fname | uppercase}} {{employeeDetails?.lname | uppercase}}</p>
              <p class="profile-sub-name">{{employeeDetails?.user_group_name}}</p>
            </div>
            <div class="dropdown-arrow"><span style="color: #fff;" class="caret"></span></div>
          </div>

          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem"><a class="dropdown-item"
                [routerLink]="['/setting-layout/settings']"><i class="fa fa-gear"></i>Settings</a></li>

            <li role="menuitem"><a class="dropdown-item" style="cursor:pointer" (click)="signOut()"><i
                  class="fa fa-sign-out"></i><strong>Sign
                  Out</strong></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="office-type-section">
        <div *ngIf="officeListArray?.length > 0">
          <ng-select id="selectOffice" [items]="officeListArray" class="office-type" [multiple]="false" [closeOnSelect]="true"
            [clearable]="false" bindLabel="office_name" bindValue="id" [searchable]="false" placeholder="Select Office"
            class="office-type-change" [(ngModel)]="selectedOffice" (change)="onChange($event)">
          </ng-select>
        </div>
      </div>

      <div class="serarch-customer-section">
        <form id="searchForm">
          <div class="form-group" >
            <label class="sr-only" for="globalCustomerSearch">Global Customer Search</label>

            <div class="dropdown searchIconXL" style="width: 100%;">
              <input type="text" class="search" name="globalCustomerSearch" id="globalCustomerSearch"
                (keyup)="onKeyUp($event)" [(ngModel)]='customerInput' placeholder="Search a customer"
                (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()"
                (keydown.ArrowDown)="onArrowDown()" (keydown.Enter)="onSelectkeyB()"
                (keydown.NumpadEnter)="onSelectkeyB()" autocomplete="off">
            </div>
            <div class="dropdown-content" *ngIf="showmsg" (clickOutside)="onClickedOutside($event)">

              <div style="height: 35px;">
                <h4 style="float: left;margin-top:0px;">Customer List</h4>
                <button type="button" class="close" aria-label="Close" (click)='closeCustomerSearch()'>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="clearfix"></div>
              <ul class="list-group">
                <a *ngFor="let item of customerList; let i = index">
                  <li class="list-group-item" (click)="onSelectCustomer(item)" [class.preSeleCust]="(i == tempSeleCust)"
                    id="block_{{i}}">
                    <div class="media">
                      <div class="media-body" style="text-align: left;">

                        <h4 class="media-heading">
                          <b
                            innerHTML="{{item.first_name | highlight : customerInput}} {{item.last_name | highlight : customerInput}}"></b>
                        </h4>
                        <p>
                          <span innerHTML="{{item.email | highlight : customerInput}}"></span>
                        </p>
                        <p>
                          <span innerHTML="{{item.city | highlight : customerInput}}"></span>
                          <span>, {{item.state}}</span>
                          <span>, {{item.zip}}</span>
                        </p>
                      </div>
                      <div class="media-right">
                        <p class="text-muted"><span> [ {{item.id}} ] </span></p>
                        <p>
                          <b>{{item.office.office_name}}</b>
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
                <a *ngIf="showmsg && customerList.length==0" class="text-center style1">
                  <li>No Customer Found</li>
                </a>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>



  </div>

  <!-- <div>
    <div class="logo-wrap1">
      <a [routerLink]="['dashboard-layout/dashboard']" routerLinkActive="active" class="logo-wrapper">
        <span class="brand-text"><img src="../../../../assets/dist/img/R_logo1 (1).png" alt="Element Pestsolution"
            height="30px" /></span>
      </a>
    </div>
  </div> -->
</nav>
<!-- /Top Menu Items -->

<!-- Left Sidebar Menu -->
<div class="fixed-sidebar-left">
  <ul class="nav navbar-nav side-nav nicescroll-bar mt-4"  (mouseenter)="scrollToActive()">
    <!-- <li class="pull-left navigationImg">
      <a [routerLink]="['dashboard-layout/dashboard']" routerLinkActive="active" id="navImage">
        <span class="brand-text">
          <img class="brand-img" src="../../../../assets/dist/img/R_logo1.png" alt="Element Pestsolution"
            height="35px" />
        </span>
      </a>
    </li> -->
    <!-- <li class="navigation-header">
      <span>Main</span>
      <hr />
    </li> -->
    <li  class="visible-xs">
      <form id="searchForm">
        <div class="form-group">
          <label class="sr-only" for="globalCustomerSearch">Global Customer Search</label>
          <div class="dropdown col-lg-12 searchIconXS">
            <input type="text" class="search" name="globalCustomerSearch" id="globalCustomerSearch"
              (keyup)="onKeyUp($event)" [(ngModel)]='customerInput' placeholder="Search a customer"
              (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUpsm()"
              (keydown.ArrowDown)="onArrowDownsm()" (keydown.Enter)="onSelectkeyB()"
              (keydown.NumpadEnter)="onSelectkeyB()">
          </div>
          <div class="dropdown-content" *ngIf="customerList?.length > 0">
            <button type="button" class="close pull-right" aria-label="Close" (click)='closeCustomerSearch()'>
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="clearfix"></div>
            <ul class="list-group">
              <a *ngFor="let item of customerList; let i = index">
                <li class="list-group-item" (click)="onSelectCustomer(item)" [class.preSeleCust]="(i == tempSeleCust)"
                  id="blocksm_{{i}}">
                  <div class="media">
                    <div class="media-body">
                      <h4 class="media-heading">
                        <b
                          innerHTML="{{item.first_name | highlight : customerInput}} {{item.last_name | highlight : customerInput}}"></b>
                        <span> [ {{item.id}} ] </span>
                      </h4>
                      <p>
                        <b>{{item.office.office_name}}</b>
                      </p>
                      <p>
                        <span innerHTML="{{item.email | highlight : customerInput}}"></span>
                      </p>
                      <p>
                        <span innerHTML="{{item.city | highlight : customerInput}}"></span>
                      </p>
                      <p>
                        <span>{{item.state}}</span>
                        <span>{{item.zip}}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </form>
    </li>
    <!-- DASHBOARD MENU -->
    <li>
      <a [routerLink]="['/dashboard-layout/dashboard']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper">
            <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/dashboard.png" alt="" />
            </div>
          </div><span class="right-nav-text">Dashboard</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>


    <!-- Recruiting Menu -->
    <li  *ngIf="permission!=null && (permission.prospect_dashboard_view==1 || permission.prospect_list==1 || permission.pending_contract_view==1 || permission.housing_list==1)">
      <!-- *ngIf="permission!=null && (permission.office_list==1 || permission.team_list==1)"> -->
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#recruiting"
        [class.active]="currentPath == 'recruit'">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/menu_icons/Recruiting.png" alt="" tourAnchor='/recruit/recruit-list'/>
          </div>
          <span class="right-nav-text">Recruiting</span>
        </div>
        <div class="pull-right"><i class="ti-angle-down "></i></div>
        <div class="clearfix"></div>
      </a>
      <ul id="recruiting" class="collapse collapse-level-1">
        <li *ngIf="permission!=null && permission.prospect_dashboard_view==1 "> 
          <a [routerLink]="['/recruit/recruit-dashboard']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/dashboard.png" alt=""/>
              </div><span class="right-nav-text">Dashboard</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li><!--  *ngIf="permission!=null && permission.office_list==1 "> -->
        <li *ngIf="permission!=null && permission.prospect_list==1">
          <a [routerLink]="['/recruit/recruit-list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Prospect-List.png" alt=""/>
              </div><span class="right-nav-text">Prospect List</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        
        <li  *ngIf="permission!=null && permission.pending_contract_view==1 "> 
          <a [routerLink]="['/recruit/pending-contract']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/office.png" alt=""/>
              </div><span class="right-nav-text">Pending Contracts 
                <span class="card-border-count">
                  <div class="font-color-count">
                    {{pendingContractCount}}
                  </div>
                </span>
              </span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li  *ngIf="permission!=null && permission.housing_list==1"><!--  *ngIf="permission!=null && permission.office_list==1 "> -->
          <a [routerLink]="['/recruit/housing-list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Housing.png" alt=""/>
              </div><span class="right-nav-text">Housing</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
      </ul>
    </li>
    <!--LOCATION MENU -->
    <li
      *ngIf="permission!=null && (permission.office_list==1 || permission.team_list==1)">
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#locations"
        [class.active]="currentPath == 'office-list' || currentPath == 'team-list' || currentPath == 'team' || currentPath == 'office'">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/location.png" alt="" tourAnchor='/office/office-list'/>
          </div>
          <span class="right-nav-text">Locations</span>
        </div>
        <div class="pull-right"><i class="ti-angle-down "></i></div>
        <div class="clearfix"></div>
      </a>
      <ul id="locations" class="collapse collapse-level-1">
        <li *ngIf="permission!=null && permission.office_list==1 ">
          <a [routerLink]="['/office/office-list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/office.png" alt=""/>
              </div><span class="right-nav-text">Office</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li *ngIf="permission!=null && permission.team_list==1">
          <a [routerLink]="['/team/team-list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/team.png" alt="" />
              </div><span class="right-nav-text">Team</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
      </ul>
    </li>
    <!-- LEAD MENU -->
    <!-- <li>
      <a [routerLink]="['/lead-layout/lead-list']" routerLinkActive="active">
        <div class="pull-left"><i class="ti-book mr-20"></i><span class="right-nav-text">Lead</span></div>
        <div class="clearfix"></div>
      </a>
    </li> -->
    <!-- CUCTOMER MENU -->
    <li *ngIf="permission!=null && permission.customer_list==1 ">
      <a  [routerLink]="['/customers/customers-list']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/customers.png" alt="" tourAnchor="/customers/customers-list"/>
          </div><span class="right-nav-text">Customers</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>

    <!-- <li *ngIf="permission!=null && permission.customer_list==1 ">
      <a  [routerLink]="['/customers/customers-lead-list']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/menu_icons/Customer-Lead.png" alt="" tourAnchor="/customers/customers-lead-list"/>
          </div><span class="right-nav-text">Customers Lead</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li> -->
    <!-- INVOICE MENU -->
    <li *ngIf="permission!=null && permission.invoice_list==1">
      <a  [routerLink]="['/report/invoice-report']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/invoice.png" alt="" tourAnchor="/report/invoice-report"/>
          </div><span class="right-nav-text">Invoice</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <!-- EMPLOYEES MENU -->
    <li *ngIf="permission!=null && permission.employee_list==1 ">
      <a  [routerLink]="['/employee/list']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/employee.png" alt="" tourAnchor="/employee/list"/>
          </div><span class="right-nav-text">Employees</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <!-- REPORTS MENU -->
    <!-- tourAnchor="report" -->
    <li *ngIf="(customer_report_access!=null && customer_report_access=='1') && (permission!=null && permission.report_list==1)">
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#report"
        [class.active]="(currentPath === 'employee-report' || currentPath === 'customer-report' || currentPath === 'sales-report' || currentPath ==='report' || currentPath ==='sales-leaderboard' || currentPath ==='payment-report')">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/report.png" alt="" tourAnchor="report"/>
          </div>
          <span class="right-nav-text">Reports</span>
        </div>
        <div class="pull-right">
          <i class="ti-angle-down"></i>
        </div>
        <div class="clearfix"></div>
      </a>
      <ul id="report" class="collapse collapse-level-1">
        <li>
          <a [routerLink]="['/report/sales-leaderboard']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Sales-leaderboard.png" alt=""/></div><span class="right-nav-text">Sales Leaderboard</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/report/sales-report']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Customer-report.png" alt=""/>
            </div><span class="right-nav-text">Customer Report</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/report/payment-report']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Payment-report.png" alt=""/></div><span class="right-nav-text">Payment Report</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/report/customer-payment/list']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Payment-report.png" alt=""/></div><span class="right-nav-text">Customer Payment</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/customer-report/sales-report/based/office']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Sales-report.png" alt=""/></div><span class="right-nav-text">Sales Report</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/customer-report/subscription/based/office']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Subscription-report.png" alt=""/></div><span class="right-nav-text">Subscription Report</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/customer-report/sales-budget/based/office']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Service-report.png" alt=""/></div><span class="right-nav-text">Customer Budget</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/customer-report/service-report/based/office']" routerLinkActive="active">
            <div class="pull-left"><div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Service-report.png" alt=""/></div><span class="right-nav-text">Service Report</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
      </ul>
    </li>
    <!-- ROUTES MENU -->
    <li
      *ngIf="permission!=null && (permission.route_map==1 || permission.existing_route==1 || permission.assign_route==1 )">
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#routes"
        [class.active]="(currentPath == 'office-routes' || currentPath === 'routes-map' || currentPath == 'knocking-areas' || currentPath == 'assign-route' || currentPath == 'automatic-routeing' || currentPath == 'knocking-areas-marker' || currentPath == 'blackout-area-list' || currentPath == 'existing-routing' || currentPath == 'technicians' || currentPath =='map')">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/routes.png" alt="" tourAnchor="routes"/>
          </div><span class="right-nav-text">Routes</span>
        </div>
        <div class="pull-right"><i class="ti-angle-down "></i></div>
        <div class="clearfix"></div>
      </a>
      <ul id="routes" class="collapse collapse-level-1">
        <li *ngIf="permission!=null && permission.assign_route==1 ">
          <a [routerLink]="['/technicians/assign-route']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon"
                  src="../../../../assets/dist/img/icons/assign-route-to-tech.png" alt="" />
              </div><span class="right-nav-text">Assign Routes to
                Technicians</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li *ngIf="permission!=null && permission.route_map==1 ">
          <a [routerLink]="['/map/routes-map']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/routes-map.png"
                  alt="" />
              </div><span class="right-nav-text">Routes Map</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li *ngIf="permission!=null && permission.existing_route==1 ">
          <a [routerLink]="['/map/existing-routing']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/existing routing.png"
                  alt="" />
              </div><span class="right-nav-text">Existing Routing</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

      </ul>
    </li>

    <li
      *ngIf="permission!=null && (permission.polygon_add==1 ||  permission.polygon_update==1 || permission.polygon_delete==1 )">
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#routes2"
        [class.active]="currentPath == 'area' || currentPath == 'marker' || currentPath =='salesmap'">

        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src=".../../../../assets/dist/img/icons/existing routing.png"
              alt="" tourAnchor="map/tracker-map"/>
          </div><span class="right-nav-text">Area Management</span>
        </div>
        <div class="pull-right"><i class="ti-angle-down "></i></div>
        <div class="clearfix"></div>
      </a>
      <ul id="routes2" class="collapse collapse-level-1">
        <li
          *ngIf="permission!=null && (permission.polygon_add==1 ||  permission.polygon_update==1 || permission.polygon_delete==1 )">
          <a [routerLink]="['/salesmap/area']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon"
                  src="../../../../assets/dist/img/icons/assign-route-to-tech.png" alt="" />
              </div><span class="right-nav-text">Sales Area</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li
          *ngIf="permission!=null && (permission.polygon_add==1 ||  permission.polygon_update==1 || permission.polygon_delete==1 )">
          <a [routerLink]="['/salesmap/blackout-area']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon"
                  src="../../../../assets/dist/img/menu_icons/Blackout-area.png" alt="" />
              </div><span class="right-nav-text">Blackout Area</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li
          *ngIf="permission!=null && (permission.pin_add==1 ||  permission.pin_update==1 || permission.pin_delete==1 )">
          <a [routerLink]="['/salesmap/marker']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/icons/routes-map.png"
                  alt="" />
              </div><span class="right-nav-text">Sales Area Marker</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
      </ul>
    </li>

      <!-- <ul id="routes2" class="collapse collapse-level-1">
        <li>
          <a [routerLink]="['salesmap/marker',officeid]" routerLinkActive="active-page">
            <div class="pull-left">
              <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/assign-route-to-tech.png"
                  alt="" />
              </div><span class="right-nav-text">Sales Area Marker</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
      </ul> -->
      <!-- <li>
          <a [routerLink]="['map/existing-routing']" routerLinkActive="active-page">
            <div class="pull-left">
              <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/existing routing.png"
                  alt="" />
              </div><span class="right-nav-text">Sales Area Marker</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['technicians/assign-route']" routerLinkActive="active-page">
            <div class="pull-left">
              <div class="icon-wrapper"><img class="icon"
                  src="../../../../assets/dist/img/icons/assign-route-to-tech.png" alt="" />
              </div><span class="right-nav-text">Restricted Area</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['technicians/assign-route']" routerLinkActive="active-page">
            <div class="pull-left">
              <div class="icon-wrapper"><img class="icon"
                  src="../../../../assets/dist/img/icons/assign-route-to-tech.png" alt="" />
              </div><span class="right-nav-text">Knocking History</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li> -->

   


    <li *ngIf="permission!=null && permission.service_list==1 ">
      <a
        [routerLink]="['/service/service-type-list']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/services.png" alt="" tourAnchor="/service/service-type-list"/>
          </div><span class="right-nav-text">Services</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <li *ngIf="+resource.is_own_company==1">
      <a href="javascript:void(0);" data-toggle="collapse" data-target="#inventory"
        [class.active]="(currentPath == 'product'||currentPath == 'customer-product-history'||currentPath == 'stock-layout'||currentPath == 'stock-history')">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/report.png" alt="" />
          </div><span class="right-nav-text">Inventory</span>
        </div>
        <div class="pull-right"><i class="ti-angle-down "></i></div>
        <div class="clearfix"></div>
      </a>
      <ul id="inventory" class="collapse collapse-level-1">
        <li>
          <a [routerLink]="['/inventory/product/list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon"
                  src="../../../../assets/dist/img/menu_icons/Add-product.png" alt="" />
              </div><span class="right-nav-text">Add Product</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/inventory/customer-product-history/list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon"
                  src="../../../../assets/dist/img/icons/report.png" alt="" />
              </div><span class="right-nav-text">Customer Product Usage History</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/inventory/stock-layout/list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/Add-stock.png"
                  alt="" />
              </div><span class="right-nav-text">Add Stock</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/inventory/stock-history/list']" routerLinkActive="active">
            <div class="pull-left">
              <div class="icon-wrapper-sub"><img class="icon" src="../../../../assets/dist/img/menu_icons/stock-history.png"
                  alt="" />
              </div><span class="right-nav-text">Stock History</span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>
  
      </ul>
    </li>
    <li *ngIf="permission != null && permission.coupon_list == 1 ">
      <a [routerLink]="['/coupon/coupons']"
        routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/menu_icons/Coupons.png" alt="" />
          </div><span class="right-nav-text">Coupons</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <li *ngIf="(tracking_access!=null && tracking_access=='1') &&(permission!=null && permission.tracker_list==1) ">
      <a tourAnchor="/tracker-map/salesman" [routerLink]="['/tracker-map/salesman']"
        routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/tracker-map.png" alt="" />
          </div><span class="right-nav-text">Tracker Map</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>
    <li >
      <a #menuItem [routerLink]="['/budget/role-list']" routerLinkActive="active">
        <div class="pull-left">
          <div class="icon-wrapper"><img class="icon" src="../../../../assets/dist/img/icons/customers.png" alt="" tourAnchor="/budget/role-list"/>
          </div><span class="right-nav-text">Configure Budget</span>
        </div>
        <div class="clearfix"></div>
      </a>
    </li>

    <!-- <li class="navigation-header">
      <span>Office Type</span>
      <hr />
    </li>
    <li>
      <a>
        <div class="pull-left"><span class="right-nav-text">
            <div *ngIf="officeListArray?.length > 0">
              <ng-select [items]="officeListArray" [multiple]="false" [closeOnSelect]="true" [clearable]="false"
                bindLabel="office_name" bindValue="id" [searchable]="true" placeholder="Select Office"
                class="office-type-change" [(ngModel)]="selectedOffice" (change)="onChange($event)">
              </ng-select>
            </div>
          </span></div>
        <div class="clearfix"></div>
      </a>
    </li> -->
    <!-- GLOBAL SEARCH -->
    <!-- <li class="hidden-xs hidden-sm">
      <form id="searchForm">
        <div class="form-group" *ngIf="resource.user_group_id != 20">
          <label class="sr-only" for="globalCustomerSearch">Global Customer Search</label>
          <div class="dropdown searchIconXL">
            <input type="text" class="search" name="globalCustomerSearch" id="globalCustomerSearch"
              (keyup)="onKeyUp($event)" [(ngModel)]='customerInput' placeholder="Search a customer"
              (focus)="onLocationFocus()" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()"
              (keydown.ArrowDown)="onArrowDown()" (keydown.Enter)="onSelectkeyB()"
              (keydown.NumpadEnter)="onSelectkeyB()">
          </div>
          <div class="dropdown-content" *ngIf="customerList?.length > 0">
            <button type="button" class="close pull-right" aria-label="Close" (click)='closeCustomerSearch()'>
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="clearfix"></div>
            <ul class="list-group">
              <a *ngFor="let item of customerList; let i = index">
                <li class="list-group-item" (click)="onSelectCustomer(item)" [class.preSeleCust]="(i == tempSeleCust)"
                  id="block_{{i}}">
                  <div class="media">
                    <div class="media-body">
                      <h4 class="media-heading">
                        <b
                          innerHTML="{{item.first_name | highlight : customerInput}} {{item.last_name | highlight : customerInput}}"></b>
                      </h4>
                      <p>
                        <span innerHTML="{{item.email | highlight : customerInput}}"></span>
                      </p>
                      <p>
                        <span innerHTML="{{item.city | highlight : customerInput}}"></span>
                        <span>, {{item.state}}</span>
                        <span>, {{item.zip}}</span>
                      </p>
                    </div>
                    <div class="media-right">
                      <p class="text-muted"><span> [ {{item.id}} ] </span></p>
                      <p>
                        <b>{{item.office.office_name}}</b>
                      </p>
                    </div>
                  </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </form>
    </li> -->
    <!-- <li class="hidden-xs hidden-sm">
      <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
        <div id="button-basic" dropdownToggle class="dropdown-toggle" aria-controls="dropdown-basic"><i
            class="fa fa-user" style="color: #1f8ceb;font-size: 18px;"></i> <span style="color: #1f8ceb;"
            class="caret"></span>
        </div>       
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngIf="resource.user_group_id != 20"><a class="dropdown-item"
              [routerLink]="['setting-layout/settings']"><i class="fa fa-gear"></i>Settings</a></li>

          <li role="menuitem"><a class="dropdown-item" (click)="signOut()" href="#"><i
                class="fa fa-sign-out"></i><strong>Sign
                Out</strong></a>
          </li>
        </ul>
      </div>
    </li> -->
  </ul>
</div>
<!-- /Left Sidebar Menu -->


<ng-template #upgradenow>
  <app-upgrade-now [modalRef]="modalRef" (updateDetails)="getDetails()"></app-upgrade-now>
</ng-template>




