module.exports={
    FirebaseApiKey: "AIzaSyDpBKCnHxPVe2UnZM30H-8zFsQcn9mJU4w",
    FirebaseAuthDomain: "routespro-372311.firebaseapp.com",
    FirebaseDatabaseURL: 'https://routespro-372311-default-rtdb.firebaseio.com/',
    FirebaseProjectId: "routespro-372311",
    FirebaseStorageBucket: "routespro-372311.appspot.com",
    GoggleMapApiKey:'AIzaSyAbXYNiahqmRHiGrWF18b21gppAm1iOfQI',
    RoutesXApiKey:'IQlwE4yHfp3H4UXqS7PeI1Dglfx9ulvQ36HBVmPB',
    access_token_square:"EAAAELlTtIGMLLfjGgaODH8T7Xuak8pfxKyGldNSnLxZ8HonfPxXSEAxfZTEQwVE",
    // "EAAAEISDV1QMwMXSdyHKatw0ESW6JOKQPTozbq5y68veExs6OJkU5-BOp2V7jPai",
    
    square_appId : 'sandbox-sq0idb-8t2dTQ_AoIsTwEYDfbcIWw',
    // 'sandbox-sq0idb-PK2B5gL04YZlZADBUHfx4A'
}