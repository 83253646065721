import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthJwtService } from './auth-jwt.service';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
@Injectable({
  providedIn: 'root'
})
export class AuthPermissionGuardService implements CanActivate {
  Alldata = new Map<string,boolean>();
  constructor(

    private _authJwtService: AuthJwtService,
    private _alertService:AlertService
  ) { 
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean{
    this.role=route.data.role;
  
    return this.getdata();

  }

role:any[]=[];

  getdata() :any
  {
    this.Alldata.clear();
    return this._authJwtService.GetPermissionFromServer().pipe(map(res=>{
      this._authJwtService.permission_list=res.data;
      this.Alldata.set('office_list',res.data.office_list);
      this.Alldata.set('office_add',res.data.office_add);
      this.Alldata.set('office_update',res.data.office_update);
      this.Alldata.set('office_delete',res.data.office_delete);
      this.Alldata.set('team_list',res.data.team_list);
      this.Alldata.set('team_add',res.data.team_add);
      this.Alldata.set('team_update',res.data.team_update);
      this.Alldata.set('customer_list',res.data.customer_list);
      this.Alldata.set('customer_add',res.data.customer_add);
      this.Alldata.set('customer_update',res.data.customer_update);
      this.Alldata.set('customer_delete',res.data.customer_delete);

      this.Alldata.set('chemical_list',res.data.chemical_list);
      this.Alldata.set('chemical_add',res.data.chemical_add);
      this.Alldata.set('chemical_update',res.data.chemical_update);
      this.Alldata.set('chemical_delete',res.data.chemical_delete);

      this.Alldata.set('coupon_list',res.data.coupon_list);
      this.Alldata.set('coupon_add',res.data.coupon_add);
      this.Alldata.set('coupon_update',res.data.coupon_update);
      this.Alldata.set('coupon_delete',res.data.coupon_delete);

      this.Alldata.set('prospect_list',res.data.prospect_list);
      this.Alldata.set('prospect_add',res.data.prospect_add);
      this.Alldata.set('prospect_update',res.data.prospect_update);
      this.Alldata.set('prospect_delete',res.data.prospect_delete);


      this.Alldata.set('invoice_list',res.data.invoice_list);
      this.Alldata.set('invoice_send',res.data.invoice_send);
      this.Alldata.set('employee_list',res.data.employee_list);
      this.Alldata.set('employee_add',res.data.employee_add);
      this.Alldata.set('employee_update',res.data.employee_update);
      this.Alldata.set('employee_delete',res.data.employee_delete);
      this.Alldata.set('report_list',res.data.report_list);
      this.Alldata.set('sales_report',res.data.report_list);
      this.Alldata.set('route_map',res.data.route_map);
      this.Alldata.set('route_map_add',res.data.route_map_add);
      this.Alldata.set('route_map_update',res.data.route_map_update);
      this.Alldata.set('existing_route',res.data.existing_route);
      this.Alldata.set('existing_route_add',res.data.existing_route_add);
      this.Alldata.set('existing_route_update',res.data.existing_route_update);
      this.Alldata.set('assign_route',res.data.assign_route);
      this.Alldata.set('assign_route_add',res.data.assign_route_add);
      this.Alldata.set('assign_route_update',res.data.assign_route_update);
      this.Alldata.set('service_list',res.data.service_list);
      this.Alldata.set('service_add',res.data.service_add);
      this.Alldata.set('service_update',res.data.service_update);
      this.Alldata.set('tracker_list',res.data.tracker_list);
      this.Alldata.set('my_service_list',res.data.my_service_list);
      this.Alldata.set('my_service_update',res.data.my_service_update);
      this.Alldata.set('my_service_view',res.data.my_service_view);
      this.Alldata.set('polygon_add',res.data.polygon_add);
      this.Alldata.set('polygon_update',res.data.polygon_update);
      this.Alldata.set('polygon_delete',res.data.polygon_delete);
      this.Alldata.set('pin_add',res.data.pin_add);
      this.Alldata.set('pin_update',res.data.pin_update);
      this.Alldata.set('pin_delete',res.data.pin_delete);

      this.Alldata.set('send_permission',res.data.send_permission);
      this.Alldata.set('housing_add',res.data.housing_add);
      this.Alldata.set('housing_update',res.data.housing_update);
      this.Alldata.set('housing_list',res.data.housing_list);
      this.Alldata.set('housing_delete',res.data.housing_delete);
      this.Alldata.set('user_assign',res.data.user_assign);
      this.Alldata.set('prospect_dashboard_view',res.data.prospect_dashboard_view); 
      this.Alldata.set('pending_contract_view',res.data.pending_contract_view); 
      for(let i in this.role)
      {
        if(this.Alldata.get(this.role[i]) == true)
        {
          // if(this._authJwtService.shared==undefined && (this.role[i]=='tracker_list' ||this.role[i]=='sales_report' ||this.role[i]=='report_list')){
          //   temp=false;
          //   this._authJwtService.getSubscriptionDetails().subscribe(res=>{
          //     if(res.status==1){
          //         this._authJwtService.shared=res;
          //         if(this.role[i]=='tracker_list' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.tracking.is_access=='0')){
          //           this._alertService.showAlert("This action is restricted for you ! \n contact with your Administrator",'info');
          //           return false;
          //         }
          //         else if(this.role[i]=='sales_report' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.customer_report.is_access=='0')){
          //           this._alertService.showAlert("This action is restricted for you ! \n contact with your Administrator",'info');
          //           return false;
          //         }
          //         else if(this.role[i]=='report_list' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.customer_report.is_access=='0')){
          //           this._alertService.showAlert("This action is restricted for you ! \n contact with your Administrator",'info');
          //           return false;
          //         }
          //         else{
          //           return true;
          //         }
          //     }
          // });
          // }
          if(this._authJwtService.shared==undefined && (this.role[i]=='tracker_list' ||this.role[i]=='sales_report' ||this.role[i]=='report_list')){
            return true;
          }
          else if(this.role[i]=='tracker_list' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.tracking.is_access=='0')){
            this._alertService.showAlert("This action is restricted.\n Please contact your Administrator for assistance",'info');
            return false;
          }
          else if(this.role[i]=='sales_report' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.customer_report.is_access=='0')){
            this._alertService.showAlert("This action is restricted.\n Please contact your Administrator for assistance",'info');
            return false;
          }
          else if(this.role[i]=='report_list' && (this._authJwtService.shared.company_permission_list==null || this._authJwtService.shared.company_permission_list.customer_report.is_access=='0')){
            this._alertService.showAlert("This action is restricted.\n Please contact your Administrator for assistance",'info');
            return false;
          }
          else{
            return true;
          }
          
        }
      }
      this._alertService.showAlert("This action is restricted.\n Please contact your Administrator for assistance",'info');
      return false;
      }));
  }

  
}
