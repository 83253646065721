import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { LayoutModule } from "./app/layout/layout.module";
import { environment } from './environments/environment';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

if (environment.production) {
  enableProdMode();
} else if (environment.stage) {
  enableProdMode();
} else {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(LayoutModule)
  .catch(err => console.error(err));