import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { SquareSubscriptionService } from '../../shared/services/square-subscription.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthJwtService } from "../../shared/services/auth-jwt.service";
import { AlertService } from '../../shared/services/alert.service';
// import { Console } from 'console';

const process=require('env.js')
declare const loadthis
@Component({
  selector: 'app-square-layout',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.css']
})

export class SquareLayoutComponent implements OnInit {
  token:any;
  @Input() modalRef_square:any=null;
  @Input() modalRef:any=null;
  @Input() plan:string='';
  @Input() selected_billing_id:any=null;
  @Input() selected_subscription_id:any=null;
  @Input() numberOfUser:any=null;
  @Input() location_id:any=null;
  
  @Output('updateDetails') updateDetails = new EventEmitter<boolean>();
  resource:any=null;
  showCard:boolean=false;
  constructor(
    // private _squareSubscriptionService:SquareSubscriptionService,
    private _loaderservice: NgxUiLoaderService,
    private _authJwtService:AuthJwtService,
    private _alertService:AlertService){
  }
  ngOnInit(): void {
      if(this._authJwtService.shared.data==null || this._authJwtService.shared.data.square_card_id==null){
        this.showCard=true;
        loadthis(this,process.square_appId,this.location_id);
      }else{
        this.back("");
      }
  }

  back(token:any){
    const params={
      "subscription_id": +this.selected_subscription_id,
      "billing_cycle_id": +this.selected_billing_id,
      "user_count": +this.numberOfUser,
      "card_token":token,
      "square_location_id":this.location_id
    }
    this.createSubscription(params);
  }


  createSubscription(params:any){
    this._authJwtService.createSquareSubscription(params).subscribe(res=>{
      if(res.status==1){
        this._alertService.showAlert(res.message,'success');
        sessionStorage.setItem('upgrade', '1');
        this._authJwtService.callFunction.next(true);
        this.modalRef_square.hide();
        this.modalRef.hide();
        this._loaderservice.stop();
      }else{
        this._alertService.showAlert(res.message,'error');
        this.modalRef_square.hide();
        this.modalRef.hide();
        this._authJwtService.callFunction.next(false);
        this._loaderservice.stop();
      }
    },err=>{
      this._alertService.showAlert(err,'error');
      this.modalRef_square.hide();
      this.modalRef.hide();
      this._authJwtService.callFunction.next(false);
      this._loaderservice.stop();
    })

  }


  stoploader(){
  this._loaderservice.stop();
  }
  startloader(){
  this._loaderservice.start();
  }
  

}
