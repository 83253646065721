import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { Subject,BehaviorSubject } from 'rxjs';
import { createSubscriptionRequestSchema } from 'square/dist/models/createSubscriptionRequest';
@Injectable({
  providedIn: 'root'
})
export class AuthJwtService {
  public APIEndpoint = environment.baseUrl;
  public sub = new Subject();
  public permission_list = null;
  public empdetails = new Subject();
  public jwtHelper: JwtHelperService = new JwtHelperService();
  public newBSubject = new BehaviorSubject('');
  public shared:any;
  public is_upgrade:any=0;
  public callFunction=new Subject();
  public chartResize = new Subject();
  public paymentReportUpdate=new Subject();
  public employeeUpdate=new Subject();
  public officeUpdate=new Subject();
  constructor(private http: HttpClient) { }

  decodeAuthToken(token) {
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
  }

  public GetPermissionFromServer() {
    return this.http.get(this.APIEndpoint + "api/auth/access-permission-list")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


  public subscriptionDetails() {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken'),
      'companyId': ''
    })

    return this.http.get(this.APIEndpoint + "api/auth/get-compony-subscription-expire-day", { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public braintreeToken(params) {
    return this.http.post(this.APIEndpoint + "api/auth/create-braintree-customer",params)
    .pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }
  
  public createSubscription(params:any) {
    return this.http.post(this.APIEndpoint + "api/auth/company-card-save",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


  public getLocation() {
    return this.http.get(this.APIEndpoint + "api/auth/get-location-detail")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  public updateSubscription(params:any) {
    return this.http.post(this.APIEndpoint + "api/auth/square-subscription-update",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  public createSquareSubscription(params:any) {
    return this.http.post(this.APIEndpoint + "api/auth/square-subscription-create",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getSubscriptionDetails() {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(this.APIEndpoint + 'api/auth/company-subscription-detail', '', { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
}
