import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthJwtService } from '../../services/auth-jwt.service';
declare var google: any;

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css']
})
export class ColumnChartComponent implements OnInit {

  @Input() idName;
  @Input() dataValue;
  @Input() type=null;
  @Input() resetColumnSubject: Subject<any> = new Subject<any>();
  constructor(
    private _authJwtService:AuthJwtService
  ) { 
    this._authJwtService.chartResize.subscribe(res=>{
      this.drawGraph();
    });
  }
  ngOnInit() {
    /* =====Chart Init===== */
    // google.charts.load('current', {
    //   'packages': ['corechart']
    // });
    const that=this;
    $(window).resize(function(){
      that.drawGraph();
    });
    
    this.start();
    this.resetColumnSubject.subscribe(response => {
      this.dataValue=response.data;
      this.idName=response.name;
      this.type=response.type;
      this.start();
    });
  }

  /**
   * start
   */
  public start() {
    google.charts.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  public drawGraph() {
    // const rawData= [...this.dataValue];
    // rawData.map((elm)=>{
    //   if(this.type == 'Payment Types'){
    //     if(elm[0] == 'Cash'){
    //       elm.push('#0072A7');
    //     }else if(elm[0] == 'Card'){
    //       elm.push('#0092D6');
    //     }else if(elm[0] == 'ACH'){
    //       elm.push('#24B8FD');
    //     }else{
    //       elm.push('#1f8ceb');
    //     }
    //   }else{
    //     elm.push('#1f8ceb');
    //   }
    // });
    
    // let title = "";
    // let custHeight;
    // if(this.type == 'Employee Route Assign'){
    //   rawData.unshift(['Employee', 'Employee Route Assign', { role: "style" }]);
    //   title = "Count";
    //   custHeight = 300;
    // }else if(this.type == 'Appoinment Status'){
    //   rawData.unshift(['Count', 'Appoinment Status', { role: "style" }]);
    //   title = "Appoinment Status";
    //   custHeight = "100%"
    // }else if(this.type == 'Hires Stats'){
    //   rawData.unshift(['Count', 'Hires Stats', { role: "style" }]);
    //   title = "Hires Stats";
    //   custHeight = "300"
    // }else if(this.type == 'Payment Types'){
    //   rawData.unshift(['Count', 'Payment Types', { role: "style" }]);
    //   title = "Payment Types";
    //   custHeight = "100%"
    // }
    // const data = new google.visualization.arrayToDataTable(rawData);
    // let view = new google.visualization.DataView(data);
    // view.setColumns([0, 1,
    //   {
    //     calc: "stringify",
    //     sourceColumn: 1,
    //     type: "string",
    //     role: "annotation"
    //   },
    //   2]);
    //   let options = {
    //     width: "100%",
    //     height: custHeight,
    //     chartArea: {width: '50%'},
    //     bar: {groupWidth: "95%"},
    //     legend: { position: "none" },
    //     vAxis: {
    //       title: title,
    //       minValue: 0
    //     },
    //     tooltip:{textStyle: {color: '#000'}, showColorCode: true, ignoreBounds:true}
    //   };

    // let chart = new google.visualization.ColumnChart(document.getElementById(this.idName));
    // chart.draw(view, options);
    
    var data = google.visualization.arrayToDataTable(this.dataValue);   
      const options={
        legend: { position: "none" },
        backgroundColor: {
            fillOpacity: 0
        },
        colors: ["#1b2b47","#808080"],
        titleTextStyle:{
          fontSize: 20,
          bold: true,
      },
      chartArea: {left:45,
        right:5,
        top:this.type=="first_pie"?40:50,
        width: '80%', 
        height: '70%'},
        vAxis: { 
          minValue:0,
          showAxisLines:false,
          format: 'short',
          textStyle: {
          bold:true,
          },
          // viewWindow:{
          //   min:0
          // },
          titleTextStyle: {
            bold:'true',
          },
        },
        hAxis: {
          textStyle: {
          bold:true,
          }
        },
        animation:{
          startup:true,
          duration: 1000,
          easing: 'inAndOut'
        },
        bar: {groupWidth: "25%"},
      }
      const container=document.getElementById(this.idName);
      if(container!=undefined){
        let chart = new google.visualization.ColumnChart(container);
        chart.draw(data, options);
      }
      

  }

  //   /**
  //  * ColorArray
  //  */
  // public ColorArray(item):[] {
  //   let itemSplit = item.split(',');
  //   return itemSplit;
  // }

}
