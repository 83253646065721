import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';

import { MapLoaderService } from '../services/map/mapload-service';
import { LoaderService } from '../services/loader.service';
import { Subscription } from 'rxjs';

declare var google: any;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnInit, AfterViewInit, OnDestroy {

  public dataSub: Subscription;

  @Input() lat;
  @Input() lng;
  @Output() updatedLatLng  = new EventEmitter();

  public map: any;
  public location:any

  constructor(
    private _loaderService : LoaderService,
  ) { }

  ngOnInit() {
    this.dataSub = this._loaderService.castLoader.subscribe(loader => this.reloadMap(loader));
  }

  ngAfterViewInit() {  
    if (google.maps == undefined) {
      MapLoaderService.load().then(() => {
        this.setMarker();
      });
    } else {
      this.setMarker();
    }
  }

  setMarker = () => {
    this.location = {lat: this.lat, lng: this.lng};
    this.map = new google.maps.Map(document.getElementById('mapView'), {
			center: {lat: this.lat, lng: this.lng},
			zoom: 8,
    });  
    // The marker, positioned at Uluru
    let marker = new google.maps.Marker({position: this.location, map: this.map});
  }

  setEditableMarker = () => {
    this.location = {lat: this.lat, lng: this.lng};
    this.map = new google.maps.Map(document.getElementById('mapView'), {
			center: {lat: this.lat, lng: this.lng},
			zoom: 8,
    });  
    // The marker, positioned at Uluru
    let marker = new google.maps.Marker({position: this.location, map: this.map, draggable: true});
    google.maps.event.addListener(marker, 'dragend', function (event) {   
      this.lat  = event.latLng.lat();
      this.lng  = event.latLng.lng();
      this.updatedLatLng.emit([this.lat , this.lng]);
    }.bind(this));
  }

  reloadMap = (loader) => {
    if(loader == 'enable'){
      this.setEditableMarker();
    }else if(loader == 'disabled'){
      this.setMarker();
    }
  }

  ngOnDestroy = () => {
    this.dataSub.unsubscribe();
  }

}
