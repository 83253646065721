
<!-- <form id="payment-form">
  <div id="card-container"></div>
  <button id="card-button" type="button">Pay $1.00</button>
</form>
<div id="payment-status-container"></div> -->
<div class="modal-body" style="margin-top: 10px;" *ngIf="showCard">
  <div class="row">
 <!-- <div class="col-sm-3"></div> -->
  <div class="col-sm-12">
  <form id="payment-form">
    <div id="card-container"></div>
    <button id="card-button" class="btn" style="background-color: blue;color: white;width: 100%;" type="button" >Save Card & Pay</button>
  </form>
  <div id="payment-status-container"></div>
</div>
<!-- <div class="col-sm-3"></div> -->
</div>
</div>
