import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../../../environments/environment";
import { CommonService } from '../../../shared/services/common.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public APIEndpoint = environment.baseUrl;
  private dataSubject = new BehaviorSubject<any>('');
  private closePaperContract = new BehaviorSubject<any>('');
  data$ = this.dataSubject.asObservable();
  closePaperContract$ = this.closePaperContract.asObservable();

  constructor(
    private http: HttpClient,
    private _commonService: CommonService
  ) { }

  /**
   * Method         : getCustomerDetailsWithId
   * Description    : get Customer details From DataBase
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  updateData(data: any) {
    this.dataSubject.next(data);
  }
  closePaperContractData(data: any) {
    this.closePaperContract.next(data);
  }
  public getAuthorizeMakePayment(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/anet-payment`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getCustomerDetailsWithId(id) {
    return this.http.get(this.APIEndpoint + "api/auth/customer/" + id)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addCustomer
   * Description    : Add Customer details to DataBase
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public addCustomer(params) {
    return this.http.post(this.APIEndpoint + "api/auth/customer", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
 * Method         : UpdateCustomerDetails
 * Description    : Update Customer Details to DataBase
 * Date           : 2018-12-24
 * Author         : Satyabrata Rakshit(Appsbee) 
 */
  public UpdateCustomerDetails(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/customer/update-customer', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : stateList
   * Description    : state Lists
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public stateList() {
      return this.http.get(this.APIEndpoint+'api/auth/state-list')
        .pipe(map((response: Response) => {
          let responseAny: any = response;
          return responseAny;
        }),
          catchError((err: any) => {
            throw (err)
          })
        );
  }
  CustomerList(params:any){
    return this.http.post(this.APIEndpoint + "api/auth/customer/customer-list",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  prospectList(params:any){
    return this.http.post(this.APIEndpoint + "api/auth/lead-list",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  // updateLead(params:any){
  //   return this.http.post(this.APIEndpoint + "api/auth/update-lead",params)
  //     .pipe(map((response: Response) => {
  //       let responseAny: any = response;
  //       return responseAny;
  //     }),
  //       catchError((err: any) => {
  //         throw (err)
  //       })
  //     );
  // }
  prospectDetails(params:any){
    return this.http.post(this.APIEndpoint + "api/auth/lead-detail",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getSourceList
   * Description    : Source List
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public getSourceList() {
    return this.http.get(this.APIEndpoint + "api/auth/source-list")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getServiceType
   * Description    : get Service Type
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public getServiceType() {
    return this.http.get(this.APIEndpoint + 'api/auth/servicetype')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getSubscriptionList
   * Description    : get Subscription List
   * Date           : 2018-12-12
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public getSubscriptionList(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/customer/get-customer-subscription-list', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getAppointmentList
   * Description    : get Appointment list
   * Date           : 2018-12-26
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public getAppointmentList(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/customer/get-customer-appoinment-list', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : getAppointmentTimeSlot
   * Description    : get Available Appointment TimeSlots
   * Date           : 2018-12-13
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public getAppointmentTimeSlot(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/appointment/get-appoinment-timeslots', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : lockedTimeSlot
   * Description    : locked TimeSlot for 5 min
   * Date           : 2018-12-14
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public lockedTimeSlot(params) {
    return this.http.post(this.APIEndpoint + "api/auth/appointment/get-appoinment-locked-timeslot", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : addNewAppointment
   * Description    : Add New Appointment
   * Date           : 2018-12-28
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public addNewAppointment(params) {
    return this.http.post(this.APIEndpoint + "api/auth/appointment", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * addNewSubscription
   */
  public addNewSubscription(params) {
    return this.http.post(this.APIEndpoint + "api/auth/subscription", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateAppointment
   * Description    : Update Appointment
   * Date           : 2019-01-01
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public updateAppointment(params) {
    return this.http.post(this.APIEndpoint + "api/auth/appointment/update-appoinment", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : updateSubscription
   * Description    : update Subscription
   * Date           : 2019-01-01
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public updateSubscription(params) {
    return this.http.post(this.APIEndpoint + "api/auth/subscription/update-subscription", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * validateDate
   */
  public validateDate(date): boolean {
    const value = this._commonService.validSelectedDate(date);
    return value;
  }

  /**
   * getTargetPestList
   */
  public getTargetPestList(): any {
    /* const value = this._settingService.getTargetPestList();
    return value; */
    return this.http.get(this.APIEndpoint + "api/auth/office-target-pest")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


  public getAppointmentTimeSlotReschedule(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/appointment/get-appoinment-reschedule-timeslots', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


  /**
   * Method         : getSourceList
   * Description    : Source List
   * Date           : 2018-12-06
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public cancelReasonList() {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.get(this.APIEndpoint + "api/auth/cancel-reason", { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : subscriptionTemplate
   * Description    : open modal with Subscription contract template details
   * Date           : 2019-03-05
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public subscriptionTemplate(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/customer-contract/get-contract-details', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : appointMentImages
   * Description    : Get Completed Appointment Images
   * Date           : 2019-03-14
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public appointMentImages(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/appoinment-images/show-details', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * Method         : deleteCustomer
   * Description    : Delete Customer
   * Date           : 2019-06-20
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public deleteCustomer(params) {
    return this.http.post(this.APIEndpoint + "api/auth/customer/delete-customer", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }


  /**
   * Method         : deleteProspect
   * Description    : Delete Prospect
   */
  public deleteProspect(params) {
    return this.http.post(this.APIEndpoint + "api/auth/lead-delete", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * 
   * Method         : paymentNonesDetails
   * Description    : post payment nomes
   * Date           : 2019-10-21
   * Author         : Satyabrata Rakshit(Appsbee) 
   */
  public paymentNonesDetails(params) {
    return this.http.post(this.APIEndpoint + "api/auth/make-braintree-payment", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getPaymentHistory
   */
  public getPaymentHistory(params) {
    return this.http.post(this.APIEndpoint + "api/auth/customer-payment-history", params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getCustomerCardDetails
   */
  public getCustomerACHDetails(id) {
    return this.http.get(`${this.APIEndpoint}api/auth/getAuthorizeNetCustomerProfilePayment/${id}`)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getAgrementLengthByServiceID
   */
  public getContractsLengthByServiceID(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/servicetype/contracts`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * onPostPestRoutes
   */
  public onPostPestRoutes(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/post-to-pestroutes`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public onSentContractViaMail(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/send-customer-contract`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public AddACHDetails(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/createAnetCustomerProfilePayment`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * updateACHDetails
   */
  public updateACHDetails(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/updateAnetCustomerProfilePayment`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public createBraintreeCustomerToken(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/customer/create-braintree-customer-token`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * getCustomerCardDetails
   */
  public getCustomerCardDetails(id) {
    return this.http.get(`${this.APIEndpoint}api/auth/customer/get-braintree-payment-profile/${id}`)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
   * updateCardDetails
   */
  public updateCardDetails(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/customer/update-braintree-payment-profile`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
 * Send Stripe Invoice API
 */
  public sendStripeInvoice(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/send-stripe-invoice`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  /**
 * List Stripe Invoice History API
 */

  /**
* Method         : addRoutes
* Description    : Add Routes
* Date           : 2018-12-10
* Author         : Sachindra Singh(Appsbee)
*/
  public addRoutes(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/create-route-timeslot', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public sendLinkToEmail(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/send-completed-appointment-mail', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public sendLinkToSMS(params) {
    return this.http.post(this.APIEndpoint + 'api/auth/send-completed-appointment-SMS', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }





  // SALES MAN LIST (ALL EMPLOYEE LIST)
  salesMamList() {
    let OfficeId = parseInt(atob(localStorage.getItem('_usertoken')));

    let params = {
      'office_id': OfficeId,
    }
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken'),
      'companyId':''
    });
    return this.http.post<any>(this.APIEndpoint + 'api/auth/all-type-employee', params, { headers: header });
  }

  // BILLING FREQUENCY LIST
  billingFrequencyList() {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.get<any>(this.APIEndpoint + 'api/auth/billing-frequency/list', { headers: header });
  }
  // ADD NEW CUSTOMER 
  addCustomerDetails(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer', data, { headers: header });
  }

  // UPDATE NEW CUSTOMER 
  updateCustomerDetails(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer/update-customer', data, { headers: header });
  }

  // SUBSCRIPTION LIST
  subscriptionList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-subscription-list', data, { headers: header });
  }

  // SERVICE TYPE LIST
  serviceTypeList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-service-list', data, { headers: header });
  }

  // ADD ONS SERVICE LIST
  addOns(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/service-addon/list', data, { headers: header });
  }

  // ADDITIONAL SERVICES
  additionalServices(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/additional-service-with-customer', data, { headers: header });
  }

  // ADD SUBSCRIPTION DETAILS
  addSubscription(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-subscription-add', data, { headers: header });
  }

  // EDIT SUBSCRIPTION DETAILS
  editSubscription(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-subscription-update', data, { headers: header });
  }

  // GET TIME SLOT LIST
  timeSlotList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/get-appoinment-timeslots', data, { headers: header });
  }

  // ADD APPOINTMENT
  addAppointment(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-appointment-add', data, { headers: header });
  }

  // APPOINTMENT LIST
  appointmentList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-appointment-list', data, { headers: header });
  }

  // EDIT APPOINTMENT 
  editAppointment(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-appointment-update', data, { headers: header });
  }


  // CONTRACT SIGN LINK SEND
  contractSignLinkSend(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-contract-send-for-sign', data, { headers: header });
  }

  // GENERATE CUSTOMER CONTRACT SIGN LINK SEND
  generateContract(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-contract-send', data, { headers: header });
  }
  // SHOW DOCUMENT PDF
  showDocumentPdf(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-contract-show', data, { headers: header });
  }

  // CUSTOMER CHANGE STATUS
  customerChangeStatus(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer/status-change', data, { headers: header });
  }

  // CUSTOMER CHANGE STATUS
  leadChangeStatus(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/lead-status-change', data, { headers: header });
  }



  // CANCELATION REASON LIST
  cancel_reason_list() {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken'),
      'companyId':''
    })
    return this.http.get<any>(this.APIEndpoint + 'api/auth/cancel-reason', { headers: header });
  }


  // CANCEL REASON
  cancel_reason(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/subscription-status-change', data, { headers: header });
  }

  // appointment Subscription list
  appointmentSubscriptionList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/unique-subscription-list', data, { headers: header });
  }

  // payment appointment list
  // paymentAppointmentList(data) {
  //   let header = new HttpHeaders({
  //     'content-type': 'application/json',
  //     'Authorization': sessionStorage.getItem('_authToken')
  //   })
  //   return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-completed-unpaid-appoitnments', data, { headers: header });
  // }

  

  // add payement details -> send invoice
  addPayment(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/send-stripe-invoice', data, { headers: header });
  }

  // Lock time slot
  lockTimeSlot(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/customer-appoitnment-locked-timeslot', data, { headers: header });
  }


  /// appointment type list
  appointmentTypeList(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/get-appointment-type', data, { headers: header });
  }

  // property type list
  propertyTypeList() {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.get<any>(this.APIEndpoint + 'api/auth/property-type', { headers: header });
  }

  addCardWithNonce(params:any){
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(`${this.APIEndpoint}api/auth/braintree-card-delete-add`,params,{ headers: header }).pipe(map((response: Response)=>{
      let res: any = response;
      return res;
    }),
    catchError((err: any)=>{
      throw err
    })
    );
  }


  public MakeBraintreePayment(params:any) {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(`${this.APIEndpoint}api/auth/braintree-payment`, params, { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public stripeInvoiceHistory(params) {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(`${this.APIEndpoint}api/auth/all-payment-history`, params, { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public  paymentAppointmentList(params) {
    let header = new HttpHeaders({
      companyId:''
    });
    return this.http.post(`${this.APIEndpoint}api/auth/customer-unpaid-appointments
    `, params, { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public argreementLength(data) {
    let header = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': sessionStorage.getItem('_authToken')
    })
    return this.http.post<any>(this.APIEndpoint + 'api/auth/service-agreement-length/list', data, { headers: header });
  }
  public  completedAppointmentList(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/customer-completed-appoitnments`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  public  useAndNotUsedProduct(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/assign-not-assign-product-list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public addProduct(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/customer-product-add`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public deleteProduct(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/customer-product-delete`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getAllStats(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/dasboard-stats`, params).pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }
  public allUniversity(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/university-list`, params).pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }

  getDateDropdownList(){
    return this.http.get(this.APIEndpoint + "api/auth/get-date-dropdown-data")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public directRecruit(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/direct-recruit-list`, params).pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }

  getProspectStatusList() {
    return this.http.get(this.APIEndpoint + "api/auth/lead-status-list")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  getProspectProgressList() {
    return this.http.get(this.APIEndpoint + "api/auth/lead-progress-status-list")
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getHousingList() {
    return this.http.get(this.APIEndpoint + "api/auth/housing-list").pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
  }

  getDirectRecruiterList(params:any) {
    return this.http.post(this.APIEndpoint + "api/auth/direct-recruit-list",params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getActiveOfficeList() {
    const params = {
      page: "",
      limit: "",
      status: "1"
    }
    let header = new HttpHeaders({
      companyId:''
    })
    return this.http.post(this.APIEndpoint + 'api/auth/user-office-list', params, { headers: header })
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getTeamAll(OfficeId) {
    let params = { "sort_by": ["id", "desc"], "start": "0", "length": "", "office_id": OfficeId, "status": "1" }
    return this.http.post(this.APIEndpoint + 'api/auth/office-team/list', params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getStateList() {
    return this.http.get(this.APIEndpoint + 'api/auth/state-list')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public addLead(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/add-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public updateLead(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  sessionList(){
    return this.http.get(this.APIEndpoint + 'api/auth/current-and-up-comming-year')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
    
  }

  public getHousingListPost(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/get-apartment-list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getUnitList(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/unit-list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  getOfficeList(){
    return this.http.get(this.APIEndpoint + 'api/auth/current-and-up-comming-year')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
    
  }
  public getUnitReps(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/unit-wise-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public saveUnitReps(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/add-reps-in-units `, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getUnAssignedList(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/get-signed-unassigned-Lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public addApartmentHandler(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/add-apartment`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public updateApartmentHandler(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/update-apartment`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public onDeleteSalesRepHandler(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/delete-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getApartment(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/get-apartement-id`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getApartmentHistory(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getUnitHistory(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getRepHistory(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public assignProspectSlot(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public deleteApartment(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/update-lead`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public exportExcel(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/export-housing-list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getDelStatus(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/remove-unit`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public checkUnitReps(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  //contract related Api :
  public editContractTemplate(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/edit-addendum-contract-send`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getSignedNUnsignedCont(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/get-contract-list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public updateProspect(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public downloadPrivateDocument(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/get-contract-from-file-name`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  public uploadPaperWork(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/upload-paper-work`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public onDeleteContractHandler(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/delete-contract `, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public onResendContractHandler(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/resend-contract`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public contractTemplateList(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/template/lists`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  //pending contract list

  
  public getAllPendingContr(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-pending-contracts/list`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public sendContract2Admin(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/send-contract-to-admin-for-sign`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public newContractTemplate(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/send-prospect-contract`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }

  public checkPreviousContract(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/check-previous-contract-exist`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  


  // optional history
  public getHistoryByLead(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getPaymentHistoryByLead(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public update_everee(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public create_everee(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getEvereeDetailsByLead(params) {
    return this.http.post(`${this.APIEndpoint}api/auth/hiring-housing/check-unit-has-reps`, params)
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  
  

  //optional
  getAllPaymentTypes(){
    return this.http.get(this.APIEndpoint + 'api/auth/current-and-up-comming-year')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
  }
  public getPendingCount() {
    return this.http.get(this.APIEndpoint+'api/auth/hiring-pending-contracts/sign-count')
      .pipe(map((response: Response) => {
        let responseAny: any = response;
        return responseAny;
      }),
        catchError((err: any) => {
          throw (err)
        })
      );
}
getRecruitingSettingsDetails(params:any = {}) {
  return this.http.post(this.APIEndpoint + "api/auth/signing-permission-list",params)
  .pipe(map((response: Response) => {
    let responseAny: any = response;
    return responseAny;
  }),
    catchError((err: any) => {
      throw (err)
    })
  );
}
public getRoleList() {
  return this.http.get(this.APIEndpoint+'api/auth/company-user-group-list')
    .pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
}
public resendWelcomeMailProspect(params) {
  return this.http.post(this.APIEndpoint + "api/auth/send-welcome-mail", params)
    .pipe(map((response: Response) => {
      let responseAny: any = response;
      return responseAny;
    }),
      catchError((err: any) => {
        throw (err)
      })
    );
}
checkLaedAssociate(params:any = {}) {
  return this.http.post(this.APIEndpoint + "api/auth/hiring-prospect/check-lead-associate-in-housing",params)
  .pipe(map((response: Response) => {
    let responseAny: any = response;
    return responseAny;
  }),
    catchError((err: any) => {
      throw (err)
    })
  );
}

prospTileCount(params:any = {}) {
  return this.http.post(this.APIEndpoint + "api/auth/no-longer-coming-details",params)
  .pipe(map((response: Response) => {
    let responseAny: any = response;
    return responseAny;
  }),
    catchError((err: any) => {
      throw (err)
    })
  );
}
prospTileList(params:any = {}) {
  return this.http.post(this.APIEndpoint + "api/auth/no-longer-coming-tab-details",params)
  .pipe(map((response: Response) => {
    let responseAny: any = response;
    return responseAny;
  }),
    catchError((err: any) => {
      throw (err)
    })
  );
}
getHistoryProspectLog(params:any = {}) {
  return this.http.post(this.APIEndpoint + "api/auth/get-prospect-logs",params)
  .pipe(map((response: Response) => {
    let responseAny: any = response;
    return responseAny;
  }),
    catchError((err: any) => {
      throw (err)
    })
  );
}
}
