import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Constants } from './utility/constants';

@Pipe({
  name: 'routesIQDateFormater'
})
export class RoutesIQDateFormaterPipe  extends DatePipe implements PipeTransform {

  transform(value: any, format: string = Constants.DATE_TIME_FMT): any {
    if(value){
      return super.transform(value, format)
    }
    return null;
  }

}
