<div class="row">
  <div class="col-sm-12">
    <h1 class="modelDetailHeading">{{selectedType['name']}} Details</h1>
  </div>
  <div class="col-sm-4 tabRow">
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="paymentDetailsBlock">
          <h4 class="panel-title mb-3">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#paymentDetails" aria-expanded="true"
              aria-controls="paymentDetails">
              Payment Details
            </a>
          </h4>
        </div>
        <div id="paymentDetails" class="panel-collapse collapse in" role="tabpanel"
          aria-labelledby="paymentDetailsBlock">
          <ul class="list-group">
            <a *ngFor="let type of paymentTypes">
              <li class="list-group-item" [class.active]="selectedType['id'] == type.id"
                (click)="setSelectedType(type.id)">{{type.name}}</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-8" *ngIf="selectedType['id'] == 2">
    <form #paymentDetailChequeForm="ngForm" (ngSubmit)="onSubmit(paymentDetailChequeForm)">
      <div >
        <div class="form-group">
          <label for="name">Account Holder's Name</label>
          <input type="text" name="name" id="name" class="form-control" placeholder="Account Holder's Name" required
            ngModel #name="ngModel" [ngClass]="{ 'is-invalid': paymentDetailChequeForm.submitted && name.invalid }">
        </div>
        <div class="form-group">
          <label for="routingNumber">Routing Number</label>
          <input type="number" name="routingNumber" id="routingNumber" class="form-control" placeholder="Routing Number"
            required ngModel #routingNumber="ngModel"
            [ngClass]="{ 'is-invalid': paymentDetailChequeForm.submitted && routingNumber.invalid }">
        </div>
        <div class="form-group">
          <label for="accountNumber">Account Number</label>
          <input type="number" name="accountNumber" id="accountNumber" class="form-control" placeholder="Account Number"
            required ngModel #accountNumber="ngModel"
            [ngClass]="{ 'is-invalid': paymentDetailChequeForm.submitted && accountNumber.invalid }">
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">Submit</button>
      </div>
    </form>
  </div>
  <div class="col-sm-8" *ngIf="selectedType['id'] == 1">
    <form #paymentDetailCardForm="ngForm" (ngSubmit)="onSubmit(paymentDetailCardForm)">
      <div>
        <div class="form-group">
          <label for="name">Account Holder's Name</label>
          <input type="text" name="name" id="name" class="form-control" placeholder="Account Holder's Name" required
            ngModel #name="ngModel" [ngClass]="{ 'is-invalid': paymentDetailCardForm.submitted && name.invalid }">
        </div>
        <div class="form-group">
          <label for="cardNumber">Credit Card Number</label>
          <input type="number" name="cardNumber" id="cardNumber" class="form-control" placeholder="Credit Card Number"
            required ngModel #cardNumber="ngModel"
            [ngClass]="{ 'is-invalid': paymentDetailCardForm.submitted && cardNumber.invalid }">
        </div>
        <div class="form-group">
          <label for="expDate">Expiration Data</label>
          <div class="row">
            <div class="col-sm-6">
              <input type="number" name="expmth" id="expmth" class="form-control" placeholder="Month"
                min="01" max="12" maxlength="2" required ngModel #expmth="ngModel"
                [ngClass]="{ 'is-invalid': paymentDetailCardForm.submitted && expmth.invalid }">
            </div>
            <div class="col-sm-6">
              <input type="number" name="expyear" id="expyear" class="form-control" placeholder="Year"
                maxlength="4" required ngModel #expyear="ngModel"
                [ngClass]="{ 'is-invalid': paymentDetailCardForm.submitted && expyear.invalid }">
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block">Submit</button>
      </div>
    </form>
  </div>
</div>
