import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as dropin   from "braintree-web-drop-in";
import { NgxUiLoaderService } from 'ngx-ui-loader';


import { AlertService } from '../services/alert.service';
@Component({
  selector: 'app-braintree-layout',
  templateUrl: './braintree-layout.component.html',
  styleUrls: ['./braintree-layout.component.css']
})
export class BraintreeLayoutComponent implements OnInit {

  @Input() token:any=null;
  @Input() type:any=null;
  @Input() newCustomer:boolean;
  @Output() cardDetails = new EventEmitter();

  constructor(
    private _alertService: AlertService,
    private _ngxUiLoaderService:NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.initializePaymentForm();
  }
  
  /**
   * initializePaymentForm
   */
  public initializePaymentForm() {

    // this.braintreeDetails.clientToken=
    let that = this;

    dropin.create({
      authorization: this.token,
      container: '#dropin-container',
      vaultManager: true,
      card: {
        cardholderName: {
          required: true
        },
      },
      
      
      
    }, function (createErr, instance) {
      if (createErr) {
        // An error in the create call is likely due to
        // incorrect configuration values or network issues.
        // An appropriate error will be shown in the UI.
        that._alertService.showAlert('Something Went Wrong, Please Try After Some Time!!', 'error')
        console.error(createErr);
        return;
      }
      document.querySelector('#dropin-container').classList.remove("hide_btn");
      document.querySelector('#submit-button').classList.remove("hide_btn");
      that._ngxUiLoaderService.stop();
      document.querySelector('#submit-button').addEventListener('click', function () {
        that._ngxUiLoaderService.start();
        instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
          if (requestPaymentMethodErr) {
            that._ngxUiLoaderService.stop();
            console.error(requestPaymentMethodErr);
            that._alertService.showAlert(requestPaymentMethodErr['message'], 'error');
            return;
          }
          if (payload) {
            // if (that.type == 'addDetails') {
              document.querySelector('#submit-button').classList.add("hide_btn");
              document.querySelector('#dropin-container').classList.add("hide_btn");
              that.cardDetails.emit(payload);

              // document.querySelector('#submit-button').classList.add("hide_btn");
            // }
            // else if (that.type == 'payment') {
            //   console.log(payload);
            //   // Submit payload.nonce to your server
            //   // that.cardDetails.emit(payload);
            // }
          }
          else{
            that._ngxUiLoaderService.stop();
          }
        });
      });

      if (that.type == 'addDetails') {
        if (instance.isPaymentMethodRequestable()) {
          // This will be true if you generated the client token
          // with a customer ID and there is a saved payment method
          // available to tokenize with that customer.
          document.querySelector('#submit-button').classList.add("hide_btn");
        }

        instance.on('noPaymentMethodRequestable', function () {
          document.querySelector('#submit-button').classList.remove("hide_btn");
        });
      }
    });
  }

  /**
   * addId
   */
  public addId():string {
    if(this.type == 'payment'){
      return "payment";
    }
  }

  /**
   * isEditable
   */
  public isEditable():boolean {
    if(this.type == 'payment'){
      return false;
    }else if(this.type == 'addDetails'){
      return true;
    }
  }



}
